import {defineStore} from "pinia";
import {api} from "@/api";
import {ref} from "vue";

export const useAuthStore = defineStore('auth', () => {

    const profile = ref({
        name: '',
        email: '',
        avatar_url: ''
    });

    const profileForm = ref({
        name: '',
        email: ''
    });


    const passwordForm = ref({
        old_password: '',
        new_password: '',
        new_password_confirmation: ''
    });

    async function login(email, password) {
        try {
            const response = await api.post('auth/login', {email, password});
            localStorage.setItem('token', response.data.token)
        } catch (err) {
            throw new Error(err.response.data.message);
        }
    }

    async function register(name, email, password) {
        try {
            const response = await api.post('auth/register', {name, email, password});
            localStorage.setItem('token', response.data.token)
        } catch (err) {
            throw new Error(err.response.data.message);
        }
    }

    async function me() {
        try {
            const response = await api.get('auth/me');
            profile.value = response.data;
            profileForm.value = response.data;
            return response.data;
        } catch (err) {
            throw new Error(err.response.data.message);
        }
    }

    async function save(name, email) {
        try {
            const response = await api.put('auth/profile', {name, email});
            profile.value = response.data;
            return response.data;
        } catch (err) {
            throw new Error(err.response.data.message);
        }
    }

    async function changePassword() {
        if (passwordForm.value.new_password !== passwordForm.value.new_password_confirmation) {
            throw new Error('Password confirmation is invalid');
        }
        try {
            const response = await api.put('auth/password', passwordForm.value);
            return response.data;
        } catch (err) {
            throw new Error(err.response.data.message);
        }
    }


    return {login, register, me, profile, profileForm, passwordForm, save, changePassword};
});