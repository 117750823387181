<script setup>
import {useAuthStore} from "@/stores/auth.store";
import {onMounted, ref} from "vue";
import {useWebsiteStore} from "@/stores/website.store";
import Sidebar2 from "@/views/Dashboard/Sidebar2.vue";
import {Command} from "vue-command-palette";
import Sidebar from "@/views/Dashboard/Sidebar.vue";
import {useConversationStore} from "@/stores/conversation.store";

const auth = useAuthStore();
const websites = useWebsiteStore();
const conversations = useConversationStore();
const route = useRoute();
onMounted(() => {
  auth.me();
  if (route.fullPath.includes('websites'))
    websites.fetch();
});

import {watch} from "vue";
import {useMagicKeys, onClickOutside} from '@vueuse/core'
import {useRoute} from "vue-router";

const keys = useMagicKeys()
const CmdK = keys['Meta+K']
const Escape = keys['Escape']
const target = ref(null);

watch(CmdK, (v) => {
  if (v) {
    // conversations.openCommandBox();
  }
})
watch(Escape, (v) => {
  if (v) {
    conversations.closeCommandBox();
  }
})
onClickOutside(target, () => {
  conversations.closeCommandBox();
})

function handleCommandBox(value) {
  let result;
  if (value.hasOwnProperty('value') && value.value.length > 0) {
    result = value.value;
  } else if (typeof value === 'string' || value instanceof String) {
    result = value;
  }

  if (result === undefined) return;

  if (conversations.commandBoxMode === 'assignment') {
    conversations.assignToUser(result);
  } else if (conversations.commandBoxMode === 'priority') {
    conversations.setPriority(result);
  }
}

</script>

<template>
  <div class="flex h-full" style="overflow-y: auto">
    <Sidebar2/>
    <router-view/>

    <Command.Dialog :visible="conversations.showCommandBox" theme="linear" ref="target"
                    @select-item="handleCommandBox"
    >
      <template #header>
        <div class="flex">
          <span command-linear-badge="">{{ conversations.commandBoxLabel }}</span>
        </div>
        <Command.Input :placeholder="conversations.commandBoxHint"/>
      </template>
      <template #body>
        <Command.List>
          <Command.Empty>No results found.</Command.Empty>

          <!--          <Command.Item>Assign to</Command.Item>-->
          <Command.Item v-for="option in conversations.commandBoxOptions"
                        :data-value="option.label"
                        @click="handleCommandBox(option.label)"
          >
                <span class="relative flex h-3 w-3" v-if="conversations.commandBoxMode==='priority'">
            <span class="relative inline-flex rounded-full h-3 w-3 "
                  :class="{'bg-green-500':option.value===1,'bg-orange-500':option.value===2,'bg-red-500':option.value===3,}"
            ></span>
          </span>
            {{ option.label }}
          </Command.Item>
        </Command.List>
      </template>
    </Command.Dialog>
  </div>
</template>


<style lang="scss">
@import '@/assets/global';
@import '@/assets/linear';
</style>