import {defineStore} from "pinia";
import {computed, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {api, endpoint, socket} from "@/api";
import io from "socket.io-client";
import {useAuthStore} from "@/stores/auth.store";
import {useWebsiteStore} from "@/stores/website.store";

export const useConversationStore = defineStore('conversations', () => {

    const route = useRoute();
    const allConversations = ref([]);

    const auth = useAuthStore();

    const conversations = computed(() => {
        switch (route.params.status) {
            case 'awaiting':
                return allConversations.value.filter(c => c.status === 'awaiting');
            case 'assigned':
                return allConversations.value.filter(c => c.assigned_user_id === auth.profile.id && c.status === 'open');
            case 'all':
                return allConversations.value;
            case 'resolved':
                return allConversations.value.filter(c => c.status === 'resolved');
        }
        return [];
    });
    const conversation = computed(() => {
        if (!route.params.secret) return null;
        return allConversations.value.find(c => c.secret === route.params.secret);
    });
    const awaitingCount = computed(() => {
        const count = allConversations.value.filter(c => c.status === 'awaiting').length;
        if (count > 0) {
            document.title = `(${count}) Chatspell Inbox`
        } else {
            document.title = `Chatspell Inbox`
        }
        return count;
    });

    const inboxCount = computed(() => allConversations.value.filter(c => c.assigned_user_id === auth.profile.id && c.status === 'open').length);

    const showContactData = ref(true);
    const showTags = ref(false);
    const showHistory = ref(false);
    const showDetails = ref(true);
    const showActions = ref(true);

    watch(route, (newRoute) => {
        if (newRoute.params.secret) {
            socket.emit('mark_as_seen_by_team', newRoute.params.secret);
            document.getElementById('reply-input').focus();
        }
    })

    socket.on('conversation', async (newConversation, reason) => {
        console.log('conversation', newConversation);
        const index = allConversations.value.findIndex(c => c.secret === newConversation.secret);
        if (index === -1) {
            allConversations.value.unshift(newConversation);
        } else {
            allConversations.value[index] = newConversation;
        }
        if (route.params.secret) {
            const openedIndex = allConversations.value.findIndex(c => c.secret === route.params.secret);
            allConversations.value[openedIndex].team_seen_at = new Date();
        }

        if (reason === 'new_message') {
            if (route.params.secret === newConversation.secret) {
                socket.emit('mark_as_seen_by_team', route.params.secret);
            }
        }
    })

    async function load() {
        const response = await api.get('/websites/' + route.params.id + '/conversations');
        allConversations.value = response.data.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
        });
    }

    async function send(body, type = 'text') {
        const message = {
            body,
            is_from_visitor: false,
            type
        };
        await api.post('/conversations/' + conversation.value.secret + '/messages', message);
    }

    async function editStatus(status) {
        await api.put('/conversations/' + conversation.value.secret, {
            status
        });
    }

    async function editConversation(patch) {
        await api.put('/conversations/' + conversation.value.secret, patch);
    }

    function toggleShowContactData() {
        showContactData.value = !showContactData.value;
    }

    function toggleShowTags() {
        showTags.value = !showTags.value;
    }

    function toggleShowHistory() {
        showHistory.value = !showHistory.value;
    }

    function toggleShowDetails() {
        showDetails.value = !showDetails.value;
    }

    function toggleShowActions() {
        showActions.value = !showActions.value;
    }

    function getLastSeenMessage(conversation) {
        const filteredMessages = conversation.messages.filter(m => !m.is_from_visitor);
        if (filteredMessages.length === 0) return false;
        for (const filteredMessage of filteredMessages) {
            if (filteredMessage.created_at < conversation.seen_at) {
                return filteredMessage;
            }
        }
        return false;
    }

    // function setEmail(event) {
    //     conversation.value.email = event.target.innerText;
    // }

    const showCommandBox = ref(false);
    const commandBoxOptions = ref([]);
    const commandBoxLabel = computed(() => {
        switch (commandBoxMode.value) {
            case 'assignment':
                return 'Assign to';
            case 'priority':
                return 'Set priority';
        }
    });
    const commandBoxHint = computed(() => {
        switch (commandBoxMode.value) {
            case 'assignment':
                return 'Search for user...';
            case 'priority':
                return 'Select priority...';
        }
    });
    const commandBoxMode = ref('');
    const websites = useWebsiteStore();

    function openAssignmentCommandBox() {
        showCommandBox.value = true;
        commandBoxMode.value = 'assignment';
        commandBoxOptions.value = websites.team.map(member => {
            return {
                label: member.name,
                value: member.id
            }
        });
    }

    function openPriorityCommandBox() {
        showCommandBox.value = true;
        commandBoxMode.value = 'priority';
        commandBoxOptions.value = [
            {
                label: 'Low',
                value: 1
            },
            {
                label: 'Normal',
                value: 2
            },
            {
                label: 'High',
                value: 3
            },
        ];
    }

    function openCommandBox() {
        showCommandBox.value = true;

    }

    function closeCommandBox() {
        showCommandBox.value = false;
    }

    async function assignToUser(name) {
        const user = websites.team.find(member => member.name === name);
        console.log(user);
        await editConversation({
            assigned_user_id: user.id,
        });
        closeCommandBox();
    }

    async function setPriority(name) {
        const values = {
            'Low': 1,
            'Normal': 2,
            'High': 3
        };
        await editConversation({
            priority: values[name],
        });
        closeCommandBox();
    }

    return {
        conversations,
        conversation,
        load,
        send,
        editStatus,
        showContactData,
        toggleShowContactData,
        showTags,
        showHistory,
        showDetails,
        toggleShowDetails,
        awaitingCount,
        allConversations,
        showActions,
        toggleShowActions,
        getLastSeenMessage,
        inboxCount,
        editConversation,
        showCommandBox,
        commandBoxOptions,
        commandBoxLabel,
        commandBoxHint,
        commandBoxMode,
        openAssignmentCommandBox,
        openPriorityCommandBox,
        closeCommandBox,
        assignToUser,
        setPriority
    };
});