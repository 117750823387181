<script setup>

</script>

<template>
<!--  <svg class="interface-icon o__standard o__standard__inbox" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 1C1.89543 1 1 1.89543 1 3V12.5C1 13.8807 2.11929 15 3.5 15H12.5C13.8807 15 15 13.8807 15 12.5V3C15 1.89543 14.1046 1 13 1H3ZM13 2.7H3C2.83431 2.7 2.7 2.83431 2.7 3V9.7C2.7 9.86569 2.83431 10 3 10H5.5C5.77614 10 5.99359 10.2273 6.06171 10.4949C6.28197 11.3601 7.06626 12 8 12C8.93374 12 9.71803 11.3601 9.93829 10.4949C10.0064 10.2273 10.2239 10 10.5 10H13C13.1657 10 13.3 9.86569 13.3 9.7V3C13.3 2.83431 13.1657 2.7 13 2.7Z"></path></svg>-->

<!--  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"-->
<!--       stroke="currentColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"-->
<!--       class="lucide lucide-inbox w-4 h-4">-->
<!--    <polyline points="22 12 16 12 14 15 10 15 8 12 2 12"/>-->
<!--    <path-->
<!--        d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"/>-->
<!--  </svg>-->
<!--  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="height: 16px; width: 16px;">-->
<!--    <path d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375Z" />-->
<!--    <path fill-rule="evenodd" d="m3.087 9 .54 9.176A3 3 0 0 0 6.62 21h10.757a3 3 0 0 0 2.995-2.824L20.913 9H3.087Zm6.163 3.75A.75.75 0 0 1 10 12h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />-->
<!--  </svg>-->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width: 16px; height: 16px;">
    <path fill-rule="evenodd" d="M6.912 3a3 3 0 0 0-2.868 2.118l-2.411 7.838a3 3 0 0 0-.133.882V18a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0 0 17.088 3H6.912Zm13.823 9.75-2.213-7.191A1.5 1.5 0 0 0 17.088 4.5H6.912a1.5 1.5 0 0 0-1.434 1.059L3.265 12.75H6.11a3 3 0 0 1 2.684 1.658l.256.513a1.5 1.5 0 0 0 1.342.829h3.218a1.5 1.5 0 0 0 1.342-.83l.256-.512a3 3 0 0 1 2.684-1.658h2.844Z" clip-rule="evenodd" />
  </svg>



</template>

<style scoped>

</style>