import axios from "axios";
import io from "socket.io-client";

export const endpoint = process.env.NODE_ENV !== 'development' ? 'https://api.sobel.app/' : 'http://localhost:3000/';

export const api = axios.create({
    baseURL: endpoint + 'api/',
});
api.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});


export const socket = io(endpoint);