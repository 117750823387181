import {sha256} from "js-sha256";
import moment from "moment";

export function getInitialsAvatar(email) {
    if (email === '') email = 'G';
    return 'https://ui-avatars.com/api/?background=random&color=fff&name=' + email;
}

export function getHumanDate(date) {
    return moment(date).fromNow();
}

export function truncate(text, length) {
    return text.length > length ? text.substr(0, length - 1) + "..." : text;
}

export function minutesToEstimatedText(minutes) {
    if (minutes === 0 || isNaN(minutes)) return "We will reply as soon as possible";
    if (minutes < 60) return `We typically reply within ${minutes} minutes`;
    if (minutes > 60 * 24) return `We typically reply within ${Math.round(minutes / 60 / 24)} days`;
    return `We typically reply within ${Math.round(minutes / 60)} hours`;
}

export function unread(conversation, team = true) {
    try {
        const lastMessage = conversation.messages[0];
        return lastMessage.created_at > (team ? conversation.team_seen_at : conversation.seen_at);
    } catch (e) {
        return false;
    }
}
