<script setup>

</script>

<template>
  <div class="h-14 font-semibold text-lg border-b w-full flex items-center px-8 justify-between">
    <slot/>
  </div>
</template>

<style scoped>

</style>