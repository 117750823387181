<script setup>
import {useConversationStore} from "@/stores/conversation.store";
import {onMounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import Details from "@/views/Dashboard/Inbox/Details.vue";
import Chat from "@/views/Dashboard/Inbox/Chat.vue";
import shortDateDiff from 'short-date-diff';
import {getInitialsAvatar, getHumanDate, truncate, unread} from "@/utils";
import {useWebsiteStore} from "@/stores/website.store";

const route = useRoute();
const router = useRouter();
const conversations = useConversationStore();
const websites = useWebsiteStore();
onMounted(async () => {
  await conversations.load();
});

</script>

<template>
  <div class="flex grow " style="min-width: 1100px;"  v-if="websites.loaded">
    <div class="flex-none w-80 border-r  select-none">
      <div class="border-b h-16 p-4 font-semibold text-lg flex gap-4">
<!--        <transition name="widget-fade" mode="out-in">-->
          <span class="pt-0.5 capitalize" :key="route.params.status">{{route.params.status}}</span>
<!--        </transition>-->
      </div>
      <div class=" p-2 chats-list flex flex-col justify-between">
        <div>
          <!--          <div class="flex justify-between mb-3 font-medium text-sm">-->
          <!--            <div class="flex text font-gray-500 mt-1 cursor-pointer hover:bg-gray-200 py-1 px-2 rounded-lg transition" style="font-size: 13px;">-->
          <!--              Open-->
          <!--              <svg class="mt-0.5 ml-1" width="16" height="16" xmlns="http://www.w3.org/2000/svg">-->
          <!--                <path-->
          <!--                    d="M4.293 7.707a1 1 0 0 1 1.414-1.414L4.293 7.707ZM8 10l.707.707a1 1 0 0 1-1.414 0L8 10Zm2.293-3.707a1 1 0 1 1 1.414 1.414l-1.414-1.414Zm-4.586 0 3 3-1.414 1.414-3-3 1.414-1.414Zm1.586 3 3-3 1.414 1.414-3 3-1.414-1.414Z"></path>-->
          <!--              </svg>-->
          <!--            </div>-->

          <!--            <div-->
          <!--                class="flex text font-gray-500 mt-1 ml-auto cursor-pointer hover:bg-gray-200 py-1 px-2 rounded-lg transition" style="font-size: 13px;">-->
          <!--              Newest-->
          <!--              <svg class="mt-0.5 ml-1" width="16" height="16" xmlns="http://www.w3.org/2000/svg">-->
          <!--                <path-->
          <!--                    d="M4.293 7.707a1 1 0 0 1 1.414-1.414L4.293 7.707ZM8 10l.707.707a1 1 0 0 1-1.414 0L8 10Zm2.293-3.707a1 1 0 1 1 1.414 1.414l-1.414-1.414Zm-4.586 0 3 3-1.414 1.414-3-3 1.414-1.414Zm1.586 3 3-3 1.414 1.414-3 3-1.414-1.414Z"></path>-->
          <!--              </svg>-->
          <!--            </div>-->
          <!--          </div>-->

          <transition name="widget-fade" mode="out-in">
            <div v-if="conversations.conversations.length>0" :key="conversations.conversations.length">
              <div
                  class="mb-0.5 w-full border-opacity-10 p-3 hover:bg-gray-100 rounded-xl cursor-pointer transition duration-75"
                  @click="$router.push({name: 'conversation', params: {id: route.params.id, secret: conversation.secret}})"
                  :class="{'bg-gray-100': route.params.secret === conversation.secret}"
                  v-for="conversation in conversations.conversations">
                <div class="flex gap-2">
                  <img
                      :src="getInitialsAvatar(conversation.email)"
                      alt="Mateusz avatar" loading="lazy"
                      referrerpolicy="no-referrer"
                      class="w-6 h-6 rounded-full select-none">
                  <div class="flex flex-col w-full" style="font-size: 13px;">
                    <div class="flex justify-between">
                      <div class="text leading-4 flex line-clamp-1 break-all font-semibold mr-2 "
                           v-if="conversation.email" :class="{'font-bold':unread(conversation)}">{{
                          conversation.email
                        }}
                      </div>
                      <div class="flex" v-else>Guest</div>
                      <span class="text-gray-400">{{
                          shortDateDiff(new Date(conversation.created_at)).whenSuffix('s', 'now')
                        }}</span>
                    </div>
                    <div class="flex justify-between" style="font-size: 13px;">
                  <span class="text-gray-500" :class="{'font-bold':unread(conversation)}">{{
                      truncate(conversation['last_message'], 80)
                    }} </span>
                      <span class="relative flex h-3 w-3 mt-1" v-if="unread(conversation)">
                    <span
                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
                </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mx-auto text-center pt-16 flex flex-col justify-center" style="margin-top: calc(30vh);"
                 v-else>
              <h2 class="mt-4 text text-gray-500">There are no conversations</h2>
            </div>

          </transition>
        </div>

      </div>
    </div>


    <transition name="widget-fade" mode="out-in">
      <Chat v-if="conversations.conversation" :key="conversations.conversation.id"/>
    </transition>

    <transition name="widget-fade" mode="out-in">
      <Details v-if="conversations.conversation" :key="conversations.conversation.id"/>
    </transition>
  </div>

  <div v-else class="mt-12 max-w-7xl mx-auto flex justify-center h-screen">
    <div
        class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
        role="status" aria-label="loading">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<style>
.chats-list {
  height: calc(100vh - 4rem) !important;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 3px;
}


.widget-fade-enter-active,
.widget-fade-leave-active {
  transition: opacity 0.2s ease;
}

.widget-fade-enter-from,
.widget-fade-leave-to {
  opacity: 0;
}

</style>
