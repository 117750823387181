import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './styles/index.css'
import {createPinia} from "pinia";
import {vue3Debounce} from "vue-debounce";
import '@asika32764/vue-animate/dist/vue-animate.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import './styles/output.css'

String.prototype.or = function (placeholder) {
    if (this.length === 0) {
        return placeholder;
    }
    return this;
}

String.prototype.whenSuffix = function (suffix, placeholder) {
    if (this.endsWith(suffix)) {
        return placeholder;
    }
    return this;
}

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

const pinia = createPinia();
createApp(App)
    .use(router)
    .use(pinia)
    .use(Toast, {
        transition: "Vue-Toastification__bounce",
        maxToasts: 20,
        newestOnTop: true
    })
    .directive('debounce', vue3Debounce({lock: true}))
    .mount('#app')
