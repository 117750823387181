<script setup xmlns="http://www.w3.org/1999/html">

import TopBar from "@/shared/TopBar.vue";
import {useAuthStore} from "@/stores/auth.store";
import {useToast} from "vue-toastification";
import {toastConfig} from "@/constants";

const auth = useAuthStore();
const toast = useToast();

async function save() {
  if (!auth.profileForm.name || !auth.profileForm.email) {
    return;
  }
  await auth.save(auth.profileForm.name, auth.profileForm.email);
  toast.success('Profile saved!', toastConfig);
}

</script>

<template>
  <div class="w-full">
    <TopBar>
      General
    </TopBar>

    <div class="m-12 flex flex-col gap-5">
      <div>
        <label class="font-semibold">Avatar</label>
        <div class="text-center w-32 mt-3">
          <img :src="auth.profileForm.avatar_url" class="w-32 h-32 rounded-xl shadow hover:brightness-90 cursor-pointer"
               :alt="auth.profileForm.name">
          <span class="text-xs text-gray-500 hover:underline cursor-pointer">Choose</span>
        </div>
      </div>

      <form class="flex flex-col gap-5" @submit.prevent="save">

        <div class="text-md w-1/3">
          <label class="font-semibold">Your name</label>
          <div class="mt-2">
            <input type="text" id="first-name" autocomplete="given-name" placeholder="Your name"
                   v-model="auth.profileForm.name"
                   class="form-control">
          </div>
        </div>
        <div class="text-md w-1/3">
          <label class="font-semibold">Email</label>
          <div class="mt-2">
            <input type="email" id="email" autocomplete="email" placeholder="Your email"
                   v-model="auth.profileForm.email"
                   class="form-control">
          </div>
        </div>
        <div class="text-md w-1/3">
          <button type="submit" :disabled="!auth.profileForm.name || !auth.profileForm.email" class="btn">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>

</template>

<style scoped>

</style>