<script setup>

import TopBar from "@/shared/TopBar.vue";

import Compact from 'vue-color/src/components/Compact.vue'
import {useWebsiteStore} from "@/stores/website.store";
import {computed, ref} from "vue";

const websites = useWebsiteStore();

function refresh() {
  const iframeWindow = document.getElementById("iframe").contentWindow;
  iframeWindow.postMessage({
    action: "refresh",
    color: websites.widgetColor.hex,
  }, "*");
}

async function save() {
  await websites.save({
    color: websites.widgetColor.hex,
    bubble_color: websites.bubbleColor.hex,
  })
}

const previewColor = computed(()=>websites.bubbleColor.hex);

</script>

<template>
  <div class="grid grid-cols-2 w-full" v-if="websites.loaded">
    <div class="w-full">
      <TopBar>
        <div>Widget options</div>
        <button class="btn" @click="save">Save</button>
      </TopBar>

      <div class="rounded-lg m-8 p-4  flex flex-col gap-6">

        <div class="text-md">
          <label class="font-semibold">Welcome message</label>
          <div class="mt-2">
            <input type="text" id="first-name" autocomplete="off" placeholder="For example: How can we help you?"
                   class="form-control">
          </div>
        </div>

        <div class="text-md">
          <label class="font-semibold">Background color</label>
          <div class="mt-2">
            <div class="flex gap-3">
              <input type="text" id="first-name" autocomplete="off" placeholder="Color"
                     class="form-control" v-model="websites.widgetColor.hex">
              <div class="w-9 h-9 rounded-md border" :style="{'backgroundColor': websites.widgetColor.hex}"></div>
            </div>
            <Compact :value="websites.widgetColor" @input="(x)=>{websites.widgetColor=x;refresh()}"
                     class="rounded mt-4"/>
          </div>
        </div>

        <div class="text-md">
          <label class="font-semibold">Bubble color</label>
          <div class="mt-2">
            <div class="flex gap-3">
              <input type="text" id="first-name" autocomplete="off" placeholder="Bubble color"
                     class="form-control" v-model="websites.bubbleColor.hex">
              <div class="w-9 h-9 rounded-md border" :style="{'backgroundColor': websites.bubbleColor.hex}"></div>
            </div>
            <Compact :value="websites.bubbleColor" @input="(x)=>{websites.bubbleColor=x;refresh()}"
                     class="rounded mt-4"/>
          </div>
        </div>
      </div>

    </div>

    <div class="w-full flex justify-center items-center" style=" background-color: #F6F6F4;">
      <div class="scale-90">
        <iframe id="iframe" src="/widget/owUIUpGtDGkKhVZxToJQLGOF" class="border rounded-xl shadow"></iframe>

        <div class="flex justify-end mt-4">
          <div class="sobel">
            <!--    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">-->
            <!--      <path fill-rule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 0 1-3.476.383.39.39 0 0 0-.297.17l-2.755 4.133a.75.75 0 0 1-1.248 0l-2.755-4.133a.39.39 0 0 0-.297-.17 48.9 48.9 0 0 1-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97ZM6.75 8.25a.75.75 0 0 1 .75-.75h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5H12a.75.75 0 0 0 0-1.5H7.5Z" clip-rule="evenodd" />-->
            <!--    </svg>-->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="w-6 h-6">
              <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/>
              <path d="M13 8H7"/>
              <path d="M17 12H7"/>
            </svg>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
iframe {
  width: 400px;
  height: 700px;
}

.sobel {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: v-bind(previewColor);
  cursor: pointer;
  color: white;
  padding-top: 1px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
}

.sobel svg {
  width: 24px;
  height: 24px;
  margin: 12px;
}
</style>