<script setup>

import LockIcon from "@/icons/LockIcon.vue";
import DangerIcon from "@/icons/DangerIcon.vue";
import UserIcon from "@/icons/UserIcon.vue";
import {useRoute} from "vue-router";

const route = useRoute();
</script>

<template>
  <div class="w-52 pb-2 border-r flex flex-col " style="background-color: #F1F1F1">

    <div class="flex justify-between m-4 ml-6">
      <div class="font-semibold text-lg">Profile</div>
    </div>

    <router-link to="/profile/personal"
                 class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer"
                 :class="{'bg-white':route.fullPath.includes('personal')}">
      <UserIcon class="mt-0.5"/>
      <div class="flex justify-between link w-full">
        <div>Profile</div>
      </div>
    </router-link>

    <router-link to="/profile/password"
                 class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer"
                 :class="{'bg-white':route.fullPath.includes('password')}"
    >
      <LockIcon class="mt-0.5"/>
      <div class="flex justify-between link w-full">
        <div>Password</div>
      </div>
    </router-link>

    <a
        class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer"
        :class="{'bg-white':route.fullPath.includes('danger')}"
    >
      <DangerIcon class="mt-0.5"/>
      <div class="flex justify-between link w-full">
        <div class="text-red-600">Danger zone</div>
      </div>
    </a>
  </div>

</template>

<style scoped>

</style>