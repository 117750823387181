<script setup>

</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="694.85" height="597.05"
        viewBox="0 0 694.85 597.05">
        <path
            d="m395.84,593.88h-194.56c-25.35,0-45.97-20.62-45.97-45.97V45.97c0-25.35,20.62-45.97,45.97-45.97h194.56c25.35,0,45.97,20.62,45.97,45.97v501.93c0,25.35-20.62,45.97-45.97,45.97Z"
            fill="#2f2e43" stroke-width="0" />
        <path
            d="m396.04,581.47h-194.95c-19.02,0-34.5-15.48-34.5-34.5V44.08c0-19.02,15.48-34.5,34.5-34.5h194.95c19.02,0,34.5,15.48,34.5,34.5v502.89c0,19.02-15.48,34.5-34.5,34.5Z"
            fill="#fff" stroke-width="0" />
        <path
            d="m329.01,42.3h-62.04c-7.15,0-12.97-5.82-12.97-12.97s5.82-12.97,12.97-12.97h62.04c7.15,0,12.97,5.82,12.97,12.97s-5.82,12.97-12.97,12.97Z"
        fill="#2f2e43" stroke-width="0" />
    <path
        d="m339.95,249.79l-19.28-15s-22,6.86-22.57,7.14-34.15,78.16-34.15,78.16c0,0,86.02,44.02,79.73,39.73-6.29-4.28-4.99-62.3.86-69.87,5.87-7.58.86-26.58.86-26.58l-5.43-13.57h-.03Z"
        fill="#2f2e43" stroke-width="0" />
    <polygon points="286.64 284.88 312.79 284.88 312.79 326.36 283.96 316.89 286.64 284.88" fill="#f3a3a6"
        stroke-width="0" />
    <path
        d="m343.51,383.13c-3.84,1.59-7.79,2.97-11.84,4.12-9.7,2.78-19.95,4.25-30.52,4.25-9.26,0-18.26-1.13-26.87-3.28-3.04-.75-6.03-1.63-8.95-2.63-3.69-1.26-7.29-2.69-10.8-4.33l1.67-8.16,4.41-21.6,5.17-25.24.96-4.75,2.29-11.19.03-.14h52.3l.03.1,13.94,45.86,5.83,19.21,2.36,7.79Z"
        fill="#6c63ff" stroke-width="0" />
    <path
        d="m361.34,373.8c-5.61,3.63-11.58,6.76-17.83,9.33-3.84,1.59-7.79,2.97-11.84,4.12l-1.9-7.89-7.11-29.51-9.57-39.66h.01s.92-.02,2.48-.02c1.46,0,3.47.02,5.8.1,5.59.2,12.98.77,18.76,2.27,4.46,1.15,7.96,2.86,8.96,5.37.99,2.49,5.12,21.57,10.46,47.35.58,2.78,1.16,5.62,1.77,8.54Z"
        fill="#6c63ff" stroke-width="0" />
    <circle cx="309.21" cy="267.63" r="28.72" fill="#f3a3a6" stroke-width="0" />
    <path
        d="m344.99,262.36v.04l-7.04,1.21c-.38-1.55-.81-2.99-1.25-4.31-.54-1.6-1.11-3.04-1.71-4.33-1.53-3.28-3.25-5.58-5.01-7.17-6.61-5.99-13.93-2.01-14.9-2.21-1.28-.26-18.53,18.46-20.74,39.28-.23,2.31-.29,4.65-.12,7,.01.09.01.17.01.26.46,7.1-2.03,13.05-5.7,17.88-.04.05-.08.1-.12.15-1.3,1.7-2.76,3.26-4.27,4.69-4.36,4.08-9.28,7.05-12.95,8.93-2.83,1.46-4.91,2.27-5.42,2.46-.08.03-.12.04-.12.04,0,0-4.67,8.77-5.04,25.2-.15,6.89.45,15.12,2.46,24.62.42,1.98.89,4,1.44,6.08.3,1.15.58,2.29.82,3.39-3.69-1.26-7.29-2.69-10.8-4.33-2.8-1.29-5.52-2.69-8.18-4.21-2.43-1.37-4.81-2.84-7.12-4.4,1.22-2.91,2.4-5.45,3.43-7.46.7-1.37,1.33-2.48,1.84-3.29,6.29-10.01,2-27.43,2-27.43-9.71-8.57,1.71-27.43,1.71-27.43,0,0-2.01-25.15,4-30,6.01-4.86,6.29-16.87,6.29-16.87,10.59-46.01,45.16-37.15,45.16-37.15,43.51-4.78,41.43,37.82,41.34,39.33Z"
        fill="#2f2e43" stroke-width="0" />
    <path
        d="m291.9,310.36l-1.92,8.5-2.95,13-10.96,48.45-1.79,7.91c-3.04-.75-6.03-1.63-8.95-2.63-3.69-1.26-7.29-2.69-10.8-4.33-2.8-1.29-5.52-2.69-8.18-4.21.59-2.85,1.16-5.64,1.73-8.37,5.94-28.8,10.64-50.69,11.71-53.37,1.01-2.54,4.25-4.01,8.31-4.82.3-.06.62-.12.93-.17.29-.05.6-.1.89-.14,4.78-.7,10.32-.65,14.65-.43,1.5.08,2.85.18,3.97.27.63.05,1.2.11,1.66.15,1.08.11,1.71.19,1.71.19Z"
        fill="#6c63ff" stroke-width="0" />
    <path
        d="m301.15,393.5c-9.26,0-18.47-1.12-27.36-3.34-3.06-.75-6.13-1.66-9.12-2.68-3.78-1.29-7.48-2.77-10.99-4.4-2.78-1.28-5.58-2.72-8.33-4.29-2.47-1.39-4.91-2.9-7.25-4.48-31.54-21.16-50.38-56.39-50.38-94.23,0-62.54,50.88-113.43,113.43-113.43s113.43,50.88,113.43,113.43c0,38.7-19.5,74.36-52.15,95.4-5.73,3.7-11.84,6.9-18.15,9.5-3.91,1.62-7.96,3.03-12.05,4.2-10.05,2.87-20.5,4.33-31.07,4.33Zm0-222.86c-60.34,0-109.43,49.09-109.43,109.43,0,36.51,18.17,70.49,48.61,90.92,2.25,1.52,4.6,2.97,6.99,4.31,2.66,1.52,5.36,2.91,8.04,4.14,3.39,1.58,6.95,3,10.61,4.25,2.88.99,5.84,1.86,8.79,2.59,8.58,2.14,17.46,3.22,26.4,3.22,10.19,0,20.28-1.41,29.97-4.18,3.95-1.12,7.86-2.49,11.63-4.05,6.09-2.51,11.98-5.59,17.51-9.16,31.51-20.3,50.32-54.71,50.32-92.04,0-60.34-49.09-109.43-109.43-109.43Z"
        fill="#2f2e43" stroke-width="0" />
    <path
        d="m0,595.36c0,.94,1.07,1.69,2.41,1.69h690.03c1.33,0,2.41-.75,2.41-1.69s-1.07-1.69-2.41-1.69H2.41c-1.33,0-2.41.75-2.41,1.69Z"
        fill="#2f2e43" stroke-width="0" />
    <path id="uuid-3fbf081d-9ff2-4e55-8986-b74bb868839d-47-155-46-48-44-44"
        d="m434.59,320.95h0c2.13-2.09,4.75-3.63,7.65-4.49l21.09-22.99,11.12,10.66-22.6,22.18c-.97,2.84-2.61,5.38-4.8,7.41-5.41,5.17-12.58,6.5-16.02,2.97-3.45-3.52-1.86-10.57,3.54-15.73h.02Z"
        fill="#f3a3a6" stroke-width="0" />
    <path
        d="m443.38,310.6l11.2,14.08,57.89-43.82.05-.1,33.83-63.13c5.18-9.67,2.31-21.7-6.68-27.98-5.5-3.85-12.34-4.92-18.76-2.95-6.42,1.97-11.47,6.7-13.87,12.97l-21.95,57.45-41.71,53.46v.02Z"
        fill="#d5d5d6" stroke-width="0" />
    <polygon points="538.71 164.58 515.11 164.58 515.11 202.02 541.13 193.47 538.71 164.58" fill="#f3a3a6"
        stroke-width="0" />
    <path
        d="m492.41,149.02c0,14.32,11.6,25.92,25.92,25.92,14.32,0,25.92-11.6,25.92-25.92,0-14.32-11.6-25.92-25.92-25.92-14.32,0-25.92,11.6-25.92,25.92Z"
        fill="#f3a3a6" stroke-width="0" />
    <path
        d="m536.67,165.59l2.53,4.8,11.16-21.21c6.72-20.58-16.75-32.4-16.75-32.4-7.66-9.02-23.05,1.53-23.05,1.53-22.2-4.93-11.48,13.78-11.48,13.78,0,0,7.57.17,7.57,6.29,0,6.13,8.25,5.7,8.25,5.7l6.46,11.31,3.57-4.34c13.27-5.62,11.74,14.54,11.74,14.54Z"
        fill="#2f2e43" stroke-width="0" />
    <rect x="527.33" y="548.21" width="20.94" height="29.71" fill="#f3a3a6" stroke-width="0" />
    <path
        d="m506.94,595.9c9.19,0,22.61-.96,22.71-.97,3.59.32,21.51,1.73,22.4-2.37.82-3.77-.39-7.71-.56-8.25-1.73-17.13-2.37-17.33-2.75-17.44-.61-.18-2.39.67-5.28,2.53l-.18.12-.04.21c-.05.26-1.33,6.55-7.4,5.59-4.16-.66-5.51-1.58-5.94-2.03.35-.16.79-.44,1.1-.92.45-.7.53-1.6.23-2.68-.78-2.84-3.12-7.05-3.22-7.23l-.27-.48-23.8,16.06-14.7,4.2c-1.11.31-2,1.11-2.45,2.17h0c-.62,1.48-.24,3.2.96,4.28,2.67,2.4,7.98,6.51,13.54,7.02,1.48.14,3.44.19,5.64.19h0Z"
        fill="#2f2e43" stroke-width="0" />
    <rect x="583.54" y="506.94" width="20.94" height="29.71" transform="translate(-196.29 498.38) rotate(-39.6)"
        fill="#f3a3a6" stroke-width="0" />
    <path
        d="m591.18,566.73c7.08-5.86,16.81-15.15,16.88-15.22,2.97-2.03,17.68-12.37,15.75-16.1-1.77-3.43-5.21-5.7-5.69-6-12.25-12.11-12.86-11.85-13.23-11.69-.59.25-1.42,2.03-2.46,5.31l-.06.2.1.19c.14.24,3.16,5.91-2.13,9.03-3.63,2.14-5.25,2.29-5.87,2.22.17-.35.33-.85.26-1.42-.1-.82-.61-1.57-1.53-2.21-2.42-1.69-6.9-3.45-7.09-3.52l-.51-.2-8.1,27.54-8.65,12.61c-.65.95-.83,2.13-.5,3.24h0c.47,1.54,1.86,2.62,3.47,2.69,3.58.15,10.29-.07,14.9-3.22,1.22-.84,2.76-2.05,4.46-3.45h0Z"
        fill="#2f2e43" stroke-width="0" />
    <path d="m492.71,319.1l31.92,237.43h23.94l15.96-237.43h-71.83,0Z" fill="#2f2e43" stroke-width="0" />
    <polygon points="554.55 448.79 602.22 510.64 581.82 527.49 500.01 449.68 492.7 319.1 507.33 332.18 554.55 448.79"
        fill="#2f2e43" stroke-width="0" />
    <path d="m507.33,179.43l-14.63,139.67h71.83l5.57-45.33c3.43-28-2.28-56.36-16.28-80.84l-11.02-19.26-35.47,5.76h0Z"
        fill="#d5d5d6" stroke-width="0" />
    <path id="uuid-6d861307-5b4f-42f9-9fe9-ab52d0b2e64d-48-156-47-49-45-45"
        d="m500.89,358.79h0c1.07-2.79,2.82-5.28,5.1-7.26l9.65-29.68,14.55,5.09-11.35,29.55c.31,2.98-.14,5.97-1.29,8.74-2.77,6.94-8.75,11.13-13.34,9.35-4.59-1.78-6.07-8.85-3.3-15.79h-.02Z"
        fill="#f3a3a6" stroke-width="0" />
    <path
        d="m504.62,345.66l16.03,8.16,34.49-63.89v-.11s4.59-71.47,4.59-71.47c.7-10.94-6.9-20.7-17.69-22.68-6.61-1.21-13.28.65-18.29,5.11-5.03,4.46-7.66,10.87-7.24,17.57l3.87,61.37-15.76,65.95h0Z"
        fill="#d5d5d6" stroke-width="0" />
</svg></template>

<style scoped>
</style>
