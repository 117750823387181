<script setup>
import { useConversationStore } from "@/stores/conversation.store";
import { sha256 } from "js-sha256";
import moment from "moment/moment";
import { ref } from "vue";
import { getInitialsAvatar, getHumanDate } from "@/utils";
import { useRoute, useRouter } from "vue-router";
import shortDateDiff from "short-date-diff";
import { useAuthStore } from "@/stores/auth.store";
import { useWebsiteStore } from "@/stores/website.store";

const conversations = useConversationStore();
const websites = useWebsiteStore();

const router = useRouter();
const route = useRoute();
const body = ref('');

const focused = ref(false);

async function send() {
    if (!conversations.conversation) {
        return;
    }
    const b = body.value.trim();
    if (!b) {
        return;
    }
    body.value = '';
    let redirect = conversations.conversation.status === 'awaiting';
    await conversations.send(b);
    // await new Audio('/drop_004.ogg').play();
    if (redirect) {
        await router.push({
            name: 'conversation',
            params: { secret: route.params.secret, status: 'assigned' }
        })
    }
}

async function markAsResolved() {
    await conversations.editStatus('resolved');
    setTimeout(async () => {
        await router.push({
            name: 'website',
            params: { id: route.params.id, status: route.params.status }
        })
    }, 100);
}

async function undoResolve() {
    const secret = route.params.secret;
    await conversations.editStatus('open');
    await router.push({
        name: 'conversation',
        params: { secret: secret, status: 'all' }
    })
}

const getElementById = (id) => document.getElementById(id);

</script>

<template>
    <div class="grow flex flex-col justify-between">
        <div>
            <div class="border-b h-16 p-4 font-semibold text-lg flex justify-between cursor-pointer">
                <span v-if="conversations.conversation && conversations.conversation.email" class="pt-0.5">
                    {{ conversations.conversation.email }}
                </span>
                <span v-else-if="conversations.conversation && !conversations.conversation.email" class="pt-0.5">
                    Guest
                </span>
                <span v-else class="pt-0.5">
                    Select conversation
                </span>

                <div v-if="conversations.conversation" class="flex gap-2 ml-2">
                    <!--          <button-->
                    <!--              class="hover:opacity-60 transition-opacity duration-100 cursor-pointer font-semibold flex flex-row items-center gap-1 text-sm rounded-lg focus:outline-none whitespace-nowrap py-2 px-3 bg-gray-200  ">-->
                    <!--            <div class="flex shrink min-w-0">-->
                    <!--              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-1">-->
                    <!--                <path fill-rule="evenodd"-->
                    <!--                      d="M12 2.25A6.75 6.75 0 0 0 5.25 9v.75a8.217 8.217 0 0 1-2.119 5.52.75.75 0 0 0 .298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 1 0 7.48 0 24.583 24.583 0 0 0 4.83-1.244.75.75 0 0 0 .298-1.205 8.217 8.217 0 0 1-2.118-5.52V9A6.75 6.75 0 0 0 12 2.25ZM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 0 0 4.496 0l.002.1a2.25 2.25 0 1 1-4.5 0Zm.75-10.5a.75.75 0 0 0 0 1.5h1.599l-2.223 3.334A.75.75 0 0 0 10.5 13.5h3a.75.75 0 0 0 0-1.5h-1.599l2.223-3.334A.75.75 0 0 0 13.5 7.5h-3Z"-->
                    <!--                      clip-rule="evenodd"/>-->
                    <!--              </svg>-->


                    <!--              <span class="ml-1 truncate shrink min-w-0">-->
                    <!--                  Snooze-->
                    <!--                </span>-->
                    <!--            </div>-->

                    <!--          </button>-->

                    <button v-if="conversations.conversation.status === 'open'" @click="markAsResolved"
                        class="hover:opacity-60 transition-opacity duration-100 cursor-pointer font-semibold flex flex-row items-center gap-1 text-sm rounded-lg focus:outline-none whitespace-nowrap py-2 px-3 bg-black text-white ">

                        <div class="flex shrink min-w-0">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                class="w-5 h-5 mr-1">
                                <path
                                    d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375Z">
                                </path>
                                <path fill-rule="evenodd"
                                    d="m3.087 9 .54 9.176A3 3 0 0 0 6.62 21h10.757a3 3 0 0 0 2.995-2.824L20.913 9H3.087ZM12 10.5a.75.75 0 0 1 .75.75v4.94l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72v-4.94a.75.75 0 0 1 .75-.75Z"
                                    clip-rule="evenodd" />
                            </svg>

                            <span class="ml-1 truncate shrink min-w-0">
                                Close
                            </span>
                        </div>

                    </button>

                    <button v-if="conversations.conversation.status === 'resolved'" @click="undoResolve"
                        class="hover:opacity-60 transition-opacity duration-100 cursor-pointer font-semibold flex flex-row items-center gap-1 text-sm rounded-lg focus:outline-none whitespace-nowrap py-2 px-3  bg-black text-white ">

                        <div class="flex shrink min-w-0">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                class="w-5 h-5 mr-1">
                                <path
                                    d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375Z" />
                                <path fill-rule="evenodd"
                                    d="m3.087 9 .54 9.176A3 3 0 0 0 6.62 21h10.757a3 3 0 0 0 2.995-2.824L20.913 9H3.087ZM12 10.5a.75.75 0 0 1 .75.75v4.94l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72v-4.94a.75.75 0 0 1 .75-.75Z"
                                    clip-rule="evenodd" />
                            </svg>

                            <span class="ml-1 truncate shrink min-w-0">
                                Open
                            </span>
                        </div>

                    </button>


                    <div class="p-1.5 hover:bg-gray-200 rounded-lg cursor-pointer ml-1 transition duration-75"
                        @click="conversations.toggleShowDetails()">
                        <svg width="18" height="18" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M13 2C14.1046 2 15 2.89543 15 4L15 12C15 13.1046 14.1046 14 13 14L3 14C1.89543 14 1 13.1046 1 12L1 4C1 2.89543 1.89543 2 3 2L13 2ZM9.15 12.3L3 12.3C2.83431 12.3 2.7 12.1657 2.7 12L2.7 4C2.7 3.83431 2.83432 3.7 3 3.7L9.15 3.7L9.15 12.3ZM10.85 12.3L13 12.3C13.1657 12.3 13.3 12.1657 13.3 12L13.3 4C13.3 3.83431 13.1657 3.7 13 3.7L10.85 3.7L10.85 12.3Z">
                            </path>
                        </svg>
                    </div>
                </div>
                <div v-else>

                    <div class="p-1.5 hover:bg-gray-200 rounded-lg cursor-pointer ml-1 transition duration-75"
                        @click="conversations.toggleShowDetails()">
                        <svg width="18" height="18" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M13 2C14.1046 2 15 2.89543 15 4L15 12C15 13.1046 14.1046 14 13 14L3 14C1.89543 14 1 13.1046 1 12L1 4C1 2.89543 1.89543 2 3 2L13 2ZM9.15 12.3L3 12.3C2.83431 12.3 2.7 12.1657 2.7 12L2.7 4C2.7 3.83431 2.83432 3.7 3 3.7L9.15 3.7L9.15 12.3ZM10.85 12.3L13 12.3C13.1657 12.3 13.3 12.1657 13.3 12L13.3 4C13.3 3.83431 13.1657 3.7 13 3.7L10.85 3.7L10.85 12.3Z">
                            </path>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="gap-3" id="chatbox" v-if="conversations.conversation">

                <TransitionGroup name="list-chat" tag="div"
                    class="chatbox list-group flex flex-col flex-col-reverse gap-3 overflow-y-scroll p-4">
                    <div class="grow" :key="'grow'"></div>

                    <div v-if="route.params.secret != null && conversations.conversation.messages.length === 0"
                        class="mt-12 max-w-7xl mx-auto flex justify-center" :key="'loader'">
                        <div class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                            role="status" aria-label="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div v-for="message in conversations.conversation.messages" :key="message.id" class="flex-none">
                        <div class="flex gap-2" v-if="message.type === 'text'"
                            :class="{ 'flex-row-reverse': !message.is_from_visitor }">
                            <img :src="message.is_from_visitor ? getInitialsAvatar(conversations.conversation.email) : websites.getTeamMember(message.user_id).avatar_url"
                                alt="Mateusz avatar" loading="lazy" referrerpolicy="no-referrer"
                                v-if="message.is_from_visitor || message.user_id"
                                class="w-6 h-6 rounded-full mt-1 select-none">
                            <div class="min-w-32 p-3 rounded-xl text-sm mb-1 hover:brightness-90" style="font-size: 14px;"
                                :class="{ 'bg-gray-100': message.is_from_visitor, 'bg-indigo-100': !message.is_from_visitor }">
                                {{ message.body }}<br />
                                <div class="text-xs text-gray-500 select-none flex justify-end mt-1">
                                    <span class="mr-1"
                                        v-if="conversations.getLastSeenMessage(conversations.conversation).id === message.id">Seen,
                                    </span>
                                    <span> {{
                                        shortDateDiff(new Date(message.created_at)).whenSuffix('s', 'now')
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </TransitionGroup>
            </div>
        </div>
        <label class=" rounded-xl p-2.5 border m-3 h-72 mb-4 transition" :class="{ 'bg-gray-100': focused }" for="reply-input">
            <div class="flex gap-1 font-semibold text-sm">
                <span
                    class="cursor-pointer underline underline-offset-8 decoration-2 hover:bg-gray-100 rounded-lg p-1 ">Reply</span>
                <span class="cursor-pointer underline-offset-8 hover:bg-gray-100 rounded-lg p-1 text-gray-500">Note</span>
            </div>
            <form @submit.prevent="send" @keyup.enter="send" class="mt-2">
                <input class="resize-none m-1 w-full focus:outline-none h-full text-sm font-semilight transition"
                    placeholder="Type something..." autocomplete="off" :class="{ 'bg-gray-100 ': focused }" v-model="body"
                    @focus="focused = true" @blur="focused = false" id="reply-input" rows="5" />
                <div class="flex justify-end mt-2">
                    <button type="submit"
                        class="mt-2 flex gap-2 w-auto bg-black focus:bg-primary-400 hover:bg-primary-400 block appearance-none rounded-xl text-white duration-100 focus:outline-none disabled:opacity-75 px-3 text-sm py-1.5">
                        Send
                    </button>
                </div>
            </form>
        </label>
    </div>
</template>


<style scoped>
.chatbox {
    height: calc(99vh - 4rem - 9rem - 1rem + 10px) !important;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 3px;
}

.list-chat-enter-active,
.list-chat-leave-active {
    transition: all 0.2s ease;
}

.list-chat-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.list-chat-enter-to {
    opacity: 1;
    transform: translateY(0);
}

.list-chat-leave-from {
    opacity: 1;
    display: none;
}

.list-chat-leave-to {
    opacity: 0;
    display: none;

}
</style>
