<script setup>
import { useAuthStore } from "@/stores/auth.store";
import { ref } from "vue";
import { useRouter } from "vue-router";
import CallingIcon from "@/icons/CallingIcon.vue";

const auth = useAuthStore();
const router = useRouter();

const email = ref('');
const password = ref('');
const test = ref('');

async function login() {
    try {
        await auth.login(email.value, password.value);
        await router.push('/websites/0/inbox/awaiting');
    } catch (e) {
        console.log(e);
    }

}
</script>

<template>
    <div class="flex h-screen">
        <div class="grid md:grid-cols-2 grid-cols-1 w-full">
            <div class="m-auto flex flex-col gap-2 ">

                <h2 class="text-3xl text-zinc-900 font-extrabold">Login to Chatspell</h2>
                <span class="text-gray-500 mt-2">Provide exceptional customer support for your product</span>

                <label class="mt-6">Email</label>
                <input type="text" v-model="email" placeholder="Enter your email"
                    class="text block w-full rounded-md border-0 py-2.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 ">
                <label class="mt-6">Password</label>
                <input type="password" v-model="password" placeholder="Enter your password"
                    class="text block w-full rounded-md border-0 py-2.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 ">

                <a class="text-indigo-500 cursor-pointer hover:underline mt-2">Forgot password?</a>

                <button type="submit" @click="login"
                    class="mt-10 rounded-md bg-indigo-600 px-4 py-3 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Sign in
                </button>

                <div class="text-center mt-2">
                    <router-link class="text-indigo-500 cursor-pointer hover:underline" to="/register">Or create an account
                    </router-link>
                </div>

            </div>
            <div class="w-full h-screen text-white hidden md:flex" style="background-color: #4E50EE">

                <div class="m-auto flex flex-col gap-2 text-center">
                    <CallingIcon />
                    <h2 class="text-3xl mt-12">Empower your customer support</h2>
                    <span class="mt-2">Provide exceptional customer support for your product</span>
                </div>


            </div>
        </div>
    </div>
</template>

<style scoped>
</style>
