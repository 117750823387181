<template>
<!--  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"-->
<!--       stroke="currentColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"-->
<!--       class="lucide lucide-bookmark-check w-4 h-4 mt-0.5">-->
<!--    <path d="m19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2Z"/>-->
<!--    <path d="m9 10 2 2 4-4"/>-->
<!--  </svg>-->
<!--  <svg class="interface-icon o__standard o__standard__inbox" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 1C1.89543 1 1 1.89543 1 3V12.5C1 13.8807 2.11929 15 3.5 15H12.5C13.8807 15 15 13.8807 15 12.5V3C15 1.89543 14.1046 1 13 1H3ZM13 2.7H3C2.83431 2.7 2.7 2.83431 2.7 3V9.7C2.7 9.86569 2.83431 10 3 10H5.5C5.77614 10 5.99359 10.2273 6.06171 10.4949C6.28197 11.3601 7.06626 12 8 12C8.93374 12 9.71803 11.3601 9.93829 10.4949C10.0064 10.2273 10.2239 10 10.5 10H13C13.1657 10 13.3 9.86569 13.3 9.7V3C13.3 2.83431 13.1657 2.7 13 2.7Z"></path></svg>-->

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width: 20px; height: 20px;">
    <path d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375Z" />
    <path fill-rule="evenodd" d="m3.087 9 .54 9.176A3 3 0 0 0 6.62 21h10.757a3 3 0 0 0 2.995-2.824L20.913 9H3.087ZM12 10.5a.75.75 0 0 1 .75.75v4.94l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l1.72 1.72v-4.94a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
  </svg>

</template>
<script>
export default {
  name: 'ResolvedIcon'
}
</script>