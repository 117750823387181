<script setup>

import {ref} from "vue";
import {useAuthStore} from "@/stores/auth.store";
import {useRouter} from "vue-router";
import CallingIcon from "@/icons/CallingIcon.vue";

const name = ref('');
const email = ref('');
const password = ref('');

const auth = useAuthStore();
const router = useRouter();

async function register() {
  try {
    await auth.register(name.value, email.value, password.value);
    await router.push('/');
  } catch (e) {
    console.log(e);
  }
}

</script>

<template>
  <div class="flex h-screen">
    <div class="grid md:grid-cols-2 grid-cols-1 w-full">
      <div class="m-auto flex flex-col gap-2 ">

        <h2 class="text-3xl text-zinc-900 font-extrabold">Get started with Chatspell</h2>
        <span class="text-gray-500 mt-2">Provide exceptional customer support for your product</span>

        <label class="mt-6">Name</label>
        <input type="text" v-model="name" autocomplete="name"
               placeholder="Enter your first and last name"
               class="block w-full rounded-md border-0 py-2.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 ">

        <label class="mt-2">Email</label>
        <input type="email" v-model="email"
               placeholder="Enter your email"
               class="block w-full rounded-md border-0 py-2.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 ">
        <label class="mt-2">Password</label>
        <input type="password" v-model="password"
               placeholder="Enter your password"
               class="block w-full rounded-md border-0 py-2.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 ">

        <button type="submit" @click="register"
                class="mt-10 rounded-md bg-indigo-600 px-4 py-3 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          Create an account
        </button>

        <div class="text-center mt-2">
          <router-link class="text-indigo-500 cursor-pointer hover:underline" to="/login">Or sign in</router-link>
        </div>

      </div>
      <div class="w-full h-screen text-white hidden md:flex" style="background-color: #4E50EE">

        <div class="m-auto flex flex-col gap-2 text-center">
          <CallingIcon/>
          <h2 class="text-3xl mt-12">Empower your customer support</h2>
          <span class="mt-2">Provide exceptional customer support for your product</span>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>

</style>