<template>
<!--  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"-->
<!--       stroke="currentColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"-->
<!--       class="lucide lucide-bell-dot w-4 h-4 mt-0.5">-->
<!--    <path d="M19.4 14.9C20.2 16.4 21 17 21 17H3s3-2 3-9c0-3.3 2.7-6 6-6 .7 0 1.3.1 1.9.3"/>-->
<!--    <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"/>-->
<!--    <circle cx="18" cy="8" r="3"/>-->
<!--  </svg>-->
<!--  <svg class="interface-icon o__inbox2 o__inbox2__unassigned" width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.635 1.133a7.031 7.031 0 0 1 2.73 0l-.29 1.472a5.53 5.53 0 0 0-2.15 0l-.29-1.472ZM2.179 4.11A7.034 7.034 0 0 1 4.11 2.18l.835 1.246c-.6.402-1.117.919-1.52 1.519L2.18 4.11Zm9.71-1.93a7.033 7.033 0 0 1 1.931 1.93l-1.246.835a5.535 5.535 0 0 0-1.519-1.52l.835-1.246ZM1 8c0-.467.046-.923.133-1.365l1.472.29a5.53 5.53 0 0 0 0 2.15l-1.472.29A7.031 7.031 0 0 1 1 8Zm13.867-1.365a7.029 7.029 0 0 1 0 2.73l-1.472-.29a5.531 5.531 0 0 0 0-2.15l1.472-.29ZM4 13.745a7.033 7.033 0 0 1-1.82-1.855l1.246-.835c.152.228.321.444.505.646.233-.48.59-.975 1.13-1.396C5.447 9.983 6.575 9.4 8 9.4s2.553.583 2.939.905c.54.421.897.917 1.13 1.396.184-.202.353-.418.505-.646l1.246.835A7.033 7.033 0 0 1 12 13.745a6.965 6.965 0 0 1-4.224 1.252A6.964 6.964 0 0 1 4 13.744Zm3.824-.248c-.05-.001-.1-.004-.149-.006h.65a5.393 5.393 0 0 1-.5.006ZM8 8.314a2.045 2.045 0 1 0 0-4.09 2.045 2.045 0 0 0 0 4.09Z"></path></svg>-->

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width: 20px; height: 20px;">
    <path d="M5.85 3.5a.75.75 0 0 0-1.117-1 9.719 9.719 0 0 0-2.348 4.876.75.75 0 0 0 1.479.248A8.219 8.219 0 0 1 5.85 3.5ZM19.267 2.5a.75.75 0 1 0-1.118 1 8.22 8.22 0 0 1 1.987 4.124.75.75 0 0 0 1.48-.248A9.72 9.72 0 0 0 19.266 2.5Z" />
    <path fill-rule="evenodd" d="M12 2.25A6.75 6.75 0 0 0 5.25 9v.75a8.217 8.217 0 0 1-2.119 5.52.75.75 0 0 0 .298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 1 0 7.48 0 24.583 24.583 0 0 0 4.83-1.244.75.75 0 0 0 .298-1.205 8.217 8.217 0 0 1-2.118-5.52V9A6.75 6.75 0 0 0 12 2.25ZM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 0 0 4.496 0l.002.1a2.25 2.25 0 1 1-4.5 0Z" clip-rule="evenodd" />
  </svg>

</template>
<script>
export default {
  name: 'UnassignedIcon'
}
</script>