<template>
<!--  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"-->
<!--       stroke="currentColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"-->
<!--       class="lucide lucide-bell-ring w-4 h-4">-->
<!--    <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"/>-->
<!--    <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"/>-->
<!--    <path d="M4 2C2.8 3.7 2 5.7 2 8"/>-->
<!--    <path d="M22 8c0-2.3-.8-4.3-2-6"/>-->
<!--  </svg>-->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width: 16px; height: 16px;">
    <path fill-rule="evenodd" d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z" clip-rule="evenodd" />
  </svg>


  <!--  <svg class="interface-icon o__inbox2 o__inbox2__spam o__by-text" width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.962 1.072a2 2 0 0 1 2 0l4.5 2.598a2 2 0 0 1 1 1.732v5.196a2 2 0 0 1-1 1.732l-4.5 2.598a2 2 0 0 1-2 0l-4.5-2.598a2 2 0 0 1-1-1.732V5.402a2 2 0 0 1 1-1.732l4.5-2.598ZM7.25 9V4h1.5v5h-1.5ZM8 12.2A1.1 1.1 0 1 0 8 10a1.1 1.1 0 0 0 0 2.2Z"></path></svg>-->
</template>
<script>
export default {
  name: 'NotificationsIcon'
}
</script>