<script setup>

import {useAuthStore} from "@/stores/auth.store";
import {useWebsiteStore} from "@/stores/website.store";
import {useRoute, useRouter} from "vue-router";
import {useConversationStore} from "@/stores/conversation.store";
import InboxIcon from "@/icons/InboxIcon.vue";
import KnowledgeBaseIcon from "@/icons/KnowledgeBaseIcon.vue";
import CustomersIcon from "@/icons/CustomersIcon.vue";
import NotificationsIcon from "@/icons/NotificationsIcon.vue";
import SettingsIcon from "@/icons/SettingsIcon.vue";
import UnassignedIcon from "@/icons/UnassignedIcon.vue";
import AllIcon from "@/icons/AllIcon.vue";
import ResolvedIcon from "@/icons/ResolvedIcon.vue";
import ProfileMenu from "@/views/Profile/ProfileMenu.vue";
import WidgetIcon from "@/icons/WidgetIcon.vue";
import WidgetMenu from "@/views/Dashboard/Widget/WidgetMenu.vue";

const auth = useAuthStore();
const websites = useWebsiteStore();
const conversations = useConversationStore();
const route = useRoute();
const router = useRouter();
websites.fetch();


function navigateToWebsite( suffix) {
  if (websites.website.id) {
    router.push(`/websites/${websites.website.id}/${suffix}`);
  } else {
    router.push(`/websites/${websites.websites[0].id}/${suffix}`);
  }
}

</script>

<template>
  <div class="w-64 flex ">

    <div class="w-12 flex flex-col justify-between" style="background-color: #EAEAE9">
      <div class="flex flex-col">
        <router-link to="/profile/personal" class="w-12 h-12 cursor-pointer tab"
                     :class="{'bg-menu border-l-2 border-blue-500 px-2.5 pt-3': route.fullPath.includes('profile'),'p-3':!route.fullPath.includes('profile')}"
        >
          <img :src="auth.profile.avatar_url" :alt="auth.profile.name" class="w-6 h-6 rounded-full cursor-pointer">
        </router-link>
        <a class="w-12 h-12 border-blue-500 pt-4 tab cursor-pointer"
           @click="navigateToWebsite('inbox/awaiting')"
           :class="{'bg-menu border-l-2 pl-3.5': route.fullPath.includes('inbox'),'pl-4':!route.fullPath.includes('inbox')}"
        >
          <InboxIcon/>
        </a>
        <!--        <div class="w-12 h-12 pl-4 pt-4 tab cursor-pointer">-->
        <!--          <KnowledgeBaseIcon/>-->
        <!--        </div>-->
        <!--        <div class="w-12 h-12 p-4 tab cursor-pointer">-->
        <!--          <CustomersIcon/>-->
        <!--        </div>-->
      </div>

      <div class="flex flex-col">
        <a class="w-12 h-12 p-4 tab cursor-pointer border-blue-500"
          @click="navigateToWebsite('widget/customization')"
                     :class="{'bg-menu border-l-2 pl-3.5': route.fullPath.includes('widget'),'pl-4':!route.fullPath.includes('widget')}"
        >
          <WidgetIcon/>
        </a>
        <div class="w-12 h-12 p-4 tab cursor-pointer">
          <NotificationsIcon/>
        </div>
        <div class="w-12 h-12 p-4 tab cursor-pointer">
          <SettingsIcon/>
        </div>
      </div>
    </div>

    <div class="w-52 pb-2 border-r flex flex-col " style="background-color: #F1F1F1"
         v-if="route.fullPath.includes('inbox')">
      <div class="flex justify-between m-4 ml-6">
        <div class="font-semibold text-lg">Inbox</div>
        <div class="p-2 hover:bg-gray-200 rounded-lg cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"
               class="lucide lucide-search w-4 h-4">
            <circle cx="11" cy="11" r="8"/>
            <path d="m21 21-4.3-4.3"/>
          </svg>
        </div>
      </div>

      <router-link
          class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer"
          router-link :to="'/websites/' + websites.website.id + '/inbox/awaiting'"
          :class="{'bg-white': route.params.status === 'awaiting', 'font-semibold':conversations.awaitingCount>0}"
      >
        <UnassignedIcon class="mt-0"/>

        <div class="flex justify-between link w-full">
          <div>Unassigned</div>
          <div class="text-zinc-500">{{ conversations.awaitingCount }}</div>
        </div>
      </router-link>

      <router-link class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer link"
                   router-link :to="'/websites/' + websites.website.id + '/inbox/assigned'"
                   :class="{'bg-white': route.params.status === 'assigned'}"
      >
        <img :src="auth.profile.avatar_url" :alt="auth.profile.name" class="w-4 h-4 rounded-full cursor-pointer mt-0.5">
        <div class="flex justify-between link w-full ">
          <div>My inbox</div>
          <div class="text-zinc-500">{{ conversations.inboxCount }}</div>
        </div>
      </router-link>

      <router-link class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer link"
                   router-link :to="'/websites/' + websites.website.id + '/inbox/all'"
                   :class="{'bg-white': route.params.status === 'all'}"
      >
        <AllIcon class="mt-0"/>
        <div class="flex justify-between link w-full ">
          <div>All</div>
        </div>
      </router-link>

      <router-link class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer link"
                   router-link :to="'/websites/' + websites.website.id + '/inbox/resolved'"
                   :class="{'bg-white': route.params.status === 'resolved'}"
      >
        <ResolvedIcon class="mt-0"/>
        <div class="flex justify-between link w-full ">
          <div>Resolved</div>
        </div>
      </router-link>

      <div class="flex gap-2 mx-4 py-1 hover:bg-gray-200 rounded-lg cursor-pointer link mt-4">
        <h3 class="px-2 text-xs font-semibold uppercase leading-6 font-brand">Teams</h3>
      </div>

      <div class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer link">
        <span class="">💸</span>
        <div class="flex justify-between link w-full ">
          <div>Billing support</div>
        </div>
      </div>

      <div class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer link">
        <span class="">👋</span>
        <div class="flex justify-between link w-full ">
          <div>Tier 1 Support</div>
        </div>
      </div>

      <div class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer link">
        <span class="">💻</span>
        <div class="flex justify-between link w-full ">
          <div>Technical Support</div>
        </div>
      </div>


      <div class="flex gap-2 mx-4 py-1 hover:bg-gray-200 rounded-lg cursor-pointer link mt-4">
        <h3 class="px-2 text-xs font-semibold uppercase leading-6 font-brand">Teammates</h3>
      </div>

      <!--      <div class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer link"-->
      <!--           router-link :to="'/websites/' + websites.website.id + '/inbox/assigned'"-->
      <!--           :class="{'bg-white': route.params.status === 'assigned'}"-->
      <!--      >-->
      <!--        <img :src="auth.profile.avatar_url" :alt="auth.profile.name" class="w-4 h-4 rounded-full cursor-pointer mt-0.5">-->
      <!--        <div class="flex justify-between link w-full ">-->
      <!--          <div>{{auth.profile.name}}</div>-->
      <!--          <div class="text-zinc-500">{{conversations.inboxCount}}</div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="grow"></div>

      <div class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer link">
        <span class="">📖</span>
        <div class="flex justify-between link w-full ">
          <div>How to use Inbox?</div>
        </div>
      </div>


      <div class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer link">
        <span class="">⌨️</span>
        <div class="flex justify-between link w-full ">
          <div>Keymap</div>
        </div>
      </div>


      <div class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer link">
        <span class="">🎨</span>
        <div class="flex justify-between link w-full ">
          <div>Command palette</div>
        </div>
      </div>

    </div>

    <ProfileMenu v-if="route.fullPath.includes('profile')"/>
    <WidgetMenu v-if="route.fullPath.includes('widget')"/>

  </div>
</template>

<style scoped>
.tab:hover {
  background-color: #F1F1F1;
}

.link {
  font-size: 13px;
}
</style>