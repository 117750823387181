import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {api, endpoint, socket} from "@/api";
import {useRoute} from "vue-router";
import io from "socket.io-client";
import {unread} from "@/utils";

export const useWidgetStore = defineStore('Widget', () => {

    const website = ref({
        name: '',
        color: '',
    });
    const tab = ref('home');
    const loaded = ref(false);
    const route = useRoute();
    const failed = ref(false);

    const selected = ref(null);
    const conversation = computed(() => {
        if (selected.value === null) return null;
        return conversations.value.find(c => c.secret === selected.value);
    });

    const conversations = ref([]);
    const team = ref([]);
    const unreadCount = computed(() => {
        return conversations.value.filter(c => unread(c, false)).length;
    })

    function getTeamMember(id) {
        return team.value.find(member => member.id === id);
    }

    const socket = io(endpoint);

    socket.on('conversation', async (newConversation, reason) => {
        const index = conversations.value.findIndex(c => c.secret === newConversation.secret);
        if (index === -1) {
            conversations.value.push(newConversation);
        } else {
            if (newConversation.messages)
                conversations.value[index] = newConversation;
        }
        if (reason === 'new_message') {
            if (selected.value === newConversation.secret && tab.value === 'conversation') {
                socket.emit('mark_as_seen', selected.value);
            }
        }
    })

    function scrollToBottom() {
        setTimeout(() => {
            if (document.getElementById('conversation') == null) return;
            document.getElementById('conversation').scrollTo({
                top: document.getElementById('conversation').scrollHeight,
                behavior: 'smooth'
            });
        }, 50);
    }

    async function fetch() {
        try {
            const response = await api.get('/websites/' + route.params.key);
            website.value = response.data;
            loaded.value = true;
        } catch (error) {
            failed.value = true;
            console.log(error);
        }

        team.value = (await api.get('websites/' + website.value.id + '/team')).data;

        const secrets = listConversations();
        const items = [];
        for (const secret of secrets) {
            const conversation = await getConversation(secret);
            items.push(conversation);
        }
        items.sort((a, b) => a.created_at < b.created_at ? 1 : -1);
        conversations.value = items;

        // setInterval(async () => {
        //     if (selected.value) socket.emit('mark_as_seen', selected.value);
        // }, 2000);

        window.addEventListener('message', function (event) {
            if(event.data.action ==='refresh'){
                website.value.color = event.data.color;
            }
        })
    }

    function navigate(newTab) {
        // loading.value = true;
        if (newTab === 'home')
            selected.value = null;
        tab.value = newTab;

        setTimeout(() => {
            loading.value = false;
        }, 50);
    }

    const loading = ref(false);

    async function postMessage(body, type = 'text') {
        await api.post('/conversations/' + conversation.value.secret + '/messages', {
            body,
            is_from_visitor: true,
            type,
        });
        if (type === 'text') {
            const c = conversations.value.find(c => c.secret === conversation.value.secret)
            if (c) c.last_message = body;
        }
        scrollToBottom();
    }

    async function createConversation(body) {
        let email = null;
        if (localStorage.getItem('email' + website.value.id)) {
            email = localStorage.getItem('email' + website.value.id);
        }
        const response = await api.post('/websites/' + route.params.key + '/conversations', {
            initial_message: body,
            last_message: body,
            platform: 'widget',
            ip: '',
            email
        });
        conversations.value.push(response.data);
        selected.value = response.data.secret;
        socket.emit('subscribe_conversation', response.data.secret);
        await postMessage(body);
        setTimeout(async () => {
            await postMessage('', 'email_prompt');
        }, 1000);
        saveConversation(conversation.value);
        await fetch();
    }

    async function fillEmail(email) {
        const response = await api.put('/conversations/' + conversation.value.secret, {
            email: email
        });
        conversation.value = response.data;
        localStorage.setItem('email' + website.value.id, email);
    }

    function saveConversation(conversation) {
        const indices = localStorage.getItem('conversations' + website.value.id) ? JSON.parse(localStorage.getItem('conversations' + website.value.id)) : [];
        indices.push(conversation.secret);
        localStorage.setItem('conversations' + website.value.id, JSON.stringify(indices));
        window.parent.postMessage('sobel_conversations_' + JSON.stringify(indices), '*');
    }

    function listConversations() {
        return localStorage.getItem('conversations' + website.value.id) ? JSON.parse(localStorage.getItem('conversations' + website.value.id)) : [];
    }

    async function getConversation(secret) {
        const response = await api.get('/conversations/' + secret);
        socket.emit('subscribe_conversation', response.data.secret);
        return response.data;
    }

    function viewConversation(secret) {
        selected.value = secret;
        socket.emit('mark_as_seen', secret);
    }

    function getLastSeenMessage(conversation) {
        const filteredMessages = conversation.messages.filter(m => m.is_from_visitor);
        if (filteredMessages.length === 0) return false;
        for (const filteredMessage of filteredMessages) {
            if (filteredMessage.created_at < conversation.team_seen_at) {
                return filteredMessage;
            }
        }
        return false;
    }

    return {
        selected,
        website,
        tab,
        navigate,
        fetch,
        loaded,
        conversation,
        failed,
        createConversation,
        fillEmail,
        postMessage,
        conversations,
        scrollToBottom,
        viewConversation,
        getTeamMember,
        getLastSeenMessage,
        loading,
        unreadCount
    };
})