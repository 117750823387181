<script setup>
import {useConversationStore} from "@/stores/conversation.store";
import {useAuthStore} from "@/stores/auth.store";
import {useWebsiteStore} from "@/stores/website.store";
import shortDateDiff from "short-date-diff";
import moment from "moment";
import contenteditable from 'vue-contenteditable';
import {ref} from "vue";

const conversations = useConversationStore();
const auth = useAuthStore();
const websites = useWebsiteStore();

function editConversationDetails() {
  conversations.editConversation({
    email: conversations.conversation.email,
    phone: conversations.conversation.phone,
    name: conversations.conversation.name,
    location: conversations.conversation.location
  });
}

</script>

<template>
  <div class="flex-none w-80 border-l font-medium" v-if="conversations.showDetails" style="font-size: 13px;">
    <div class="border-b h-16 p-4 font-semibold text-lg flex justify-between">
      <span class="pt-0.5">Details</span>

      <div class="p-1.5 hover:bg-gray-200 rounded-lg cursor-pointer transition"
           @click="conversations.toggleShowDetails()"
      >
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9.793 11.207a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM6.207 4.793a1 1 0 1 0-1.414 1.414l1.414-1.414Zm5 1.414a1 1 0 0 0-1.414-1.414l1.414 1.414ZM4.793 9.793a1 1 0 0 0 1.414 1.414L4.793 9.793Zm6.414 0-2.5-2.5-1.414 1.414 2.5 2.5 1.414-1.414Zm-2.5-2.5-2.5-2.5-1.414 1.414 2.5 2.5 1.414-1.414Zm0 1.414 2.5-2.5-1.414-1.414-2.5 2.5 1.414 1.414ZM7.293 7.293l-2.5 2.5 1.414 1.414 2.5-2.5-1.414-1.414Z"></path>
        </svg>
      </div>

    </div>
    <div class="flex flex-col gap-1 pt-4" v-if="conversations.conversation">
      <div class="grid grid-cols-3 px-4" @click="conversations.openAssignmentCommandBox">
        <span class="text-gray-500 p-1">Assignee</span>
        <div class="hover:bg-gray-100 rounded-lg p-1 flex gap-2 cursor-pointer transition col-span-2"
             v-if="conversations.conversation.assigned_user_id">
          <img :src="websites.getTeamMember(conversations.conversation.assigned_user_id).avatar_url"
               :alt="websites.getTeamMember(conversations.conversation.assigned_user_id).name" loading="lazy"
               referrerpolicy="no-referrer" class="w-6 h-6 rounded-full bg-gray-200 border border-gray-300 -mt-0.5">
          {{ websites.getTeamMember(conversations.conversation.assigned_user_id).name }}
        </div>
        <div class="hover:bg-gray-100 rounded-lg p-1 flex gap-2 cursor-pointer transition col-span-2 text-gray-600"
             v-else>
          Not assigned
        </div>
      </div>
      <div class="grid grid-cols-3 px-4" @click="conversations.openPriorityCommandBox()">
        <span class="text-gray-500 p-1">Priority</span>
        <div class="hover:bg-gray-100 rounded-lg p-1 flex gap-2 cursor-pointer transition col-span-2"
             v-if="conversations.conversation.priority===1">
          <span class="relative flex h-3 w-3 pt-1">
            <span class="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
          </span>
          Low
        </div>
        <div class="hover:bg-gray-100 rounded-lg p-1 flex gap-2 cursor-pointer transition col-span-2"
             v-if="conversations.conversation.priority===2">
          <span class="relative flex h-3 w-3 pt-1">
            <span class="relative inline-flex rounded-full h-3 w-3 bg-orange-500"></span>
          </span>
          Medium
        </div>
        <div class="hover:bg-gray-100 rounded-lg p-1 flex gap-2 cursor-pointer transition col-span-2"
             v-if="conversations.conversation.priority===3">
          <span class="relative flex h-3 w-3 pt-1">
            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
          </span>
          High
        </div>
        <div class="hover:bg-gray-100 rounded-lg p-1 flex gap-2 cursor-pointer transition col-span-2"
             v-if="conversations.conversation.priority===0">
          -
        </div>
      </div>
      <div class="grid grid-cols-3 px-4">
        <span class="text-gray-500 p-1">Status</span>
        <span class="hover:bg-gray-100 rounded-lg p-1 transition col-span-2 cursor-pointer"
              v-if="conversations.conversation.status==='open'">Open</span>
        <span class="hover:bg-gray-100 rounded-lg p-1 transition col-span-2 cursor-pointer"
              v-if="conversations.conversation.status==='resolved'">Resolved</span>
        <span class="hover:bg-gray-100 rounded-lg p-1 transition col-span-2 cursor-pointer"
              v-if="conversations.conversation.status==='awaiting'">Unassigned</span>
      </div>
      <div class="px-4 py-1 font-semibold text-md border-t pt-4 flex justify-between mr-1 mt-2">
        <span class="p-1">Contact data</span>
        <div class="p-1.5 hover:bg-gray-200 rounded-lg cursor-pointer transition"
             @click="conversations.toggleShowContactData()"
        >
          <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
               v-if="conversations.showContactData"
          >
            <path
                d="M4.293 9.293a1 1 0 0 0 1.414 1.414L4.293 9.293ZM8 7l.707-.707a1 1 0 0 0-1.414 0L8 7Zm2.293 3.707a1 1 0 0 0 1.414-1.414l-1.414 1.414Zm-4.586 0 3-3-1.414-1.414-3 3 1.414 1.414Zm1.586-3 3 3 1.414-1.414-3-3-1.414 1.414Z"></path>
          </svg>
          <svg width="16" height="16"
               v-if="!conversations.showContactData"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.293 7.707a1 1 0 0 1 1.414-1.414L4.293 7.707ZM8 10l.707.707a1 1 0 0 1-1.414 0L8 10Zm2.293-3.707a1 1 0 1 1 1.414 1.414l-1.414-1.414Zm-4.586 0 3 3-1.414 1.414-3-3 1.414-1.414Zm1.586 3 3-3 1.414 1.414-3 3-1.414-1.414Z"></path>
          </svg>
        </div>
      </div>

      <transition name="widget-fade" mode="out-in">
        <div class="flex flex-col gap-1 mb-2" v-if="conversations.showContactData">
          <div class="grid grid-cols-3 px-4">
            <span class="text-gray-500 p-1">Name</span>
            <contenteditable class="hover:bg-gray-100 rounded-lg p-1 col-span-2 cursor-text"
                             contenteditable="true" v-model="conversations.conversation.name" tag="div"
                             v-debounce:300ms="editConversationDetails"
                             :no-nl="true"
                             :class="{'bg-gray-100': conversations.conversation.name.length===0}"
            >
              {{
                conversations.conversation.name
              }}
            </contenteditable>
          </div>

          <div class="grid grid-cols-3 px-4">
            <span class="text-gray-500 p-1">Email</span>
            <contenteditable class="hover:bg-gray-100 rounded-lg p-1 col-span-2 cursor-text" contenteditable="true"
                             v-model="conversations.conversation.email" tag="div"
                             v-debounce:300ms="editConversationDetails"
                             :no-nl="true"
                             :class="{'bg-gray-100': conversations.conversation.email.length===0}"
            >{{
                conversations.conversation.email
              }}
            </contenteditable>
          </div>

          <div class="grid grid-cols-3 px-4">
            <span class="text-gray-500 p-1">Phone</span>
            <contenteditable class="hover:bg-gray-100 rounded-lg p-1 col-span-2 cursor-text" contenteditable="true"
                             v-model="conversations.conversation.phone" tag="div"
                             v-debounce:300ms="editConversationDetails"
                             :no-nl="true"
                             :class="{'bg-gray-100': conversations.conversation.phone.length===0}"
            >{{
                conversations.conversation.phone
              }}
            </contenteditable>
          </div>

          <div class="grid grid-cols-3 px-4">
            <span class="text-gray-500 p-1">Location</span>
            <contenteditable class="hover:bg-gray-100 rounded-lg p-1 col-span-2 cursor-text" contenteditable="true"
                             v-model="conversations.conversation.location" tag="div"
                             v-debounce:300ms="editConversationDetails"
                             :no-nl="true"
                             :class="{'bg-gray-100': conversations.conversation.location.length===0}"

            >{{ conversations.conversation.location }}
            </contenteditable>
          </div>

          <div class="grid grid-cols-3 px-4">
            <span class="text-gray-500 p-1">Last seen at</span>
            <span class="hover:bg-gray-100 rounded-lg p-1 col-span-2 cursor-text"
                  v-if="conversations.conversation.seen_at">{{
                moment(conversations.conversation.seen_at).format('MMM Do YY')
              }}</span>
            <span class="hover:bg-gray-100 rounded-lg p-1 col-span-2 cursor-text" v-else>-</span>
          </div>
        </div>
      </transition>

      <!--      <div class="px-4 py-1 font-semibold text-md border-t pt-4 flex justify-between mr-1 mt-2">-->
      <!--        <span class="p-1">Tags</span>-->
      <!--        <div class="p-1.5 hover:bg-gray-200 rounded-lg cursor-pointer transition"-->
      <!--             @click="conversations.toggleShowTags()"-->
      <!--        >-->
      <!--          <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"-->
      <!--               v-if="conversations.showTags"-->
      <!--          >-->
      <!--            <path-->
      <!--                d="M4.293 9.293a1 1 0 0 0 1.414 1.414L4.293 9.293ZM8 7l.707-.707a1 1 0 0 0-1.414 0L8 7Zm2.293 3.707a1 1 0 0 0 1.414-1.414l-1.414 1.414Zm-4.586 0 3-3-1.414-1.414-3 3 1.414 1.414Zm1.586-3 3 3 1.414-1.414-3-3-1.414 1.414Z"></path>-->
      <!--          </svg>-->
      <!--          <svg width="16" height="16"-->
      <!--               v-if="!conversations.showTags"-->
      <!--               xmlns="http://www.w3.org/2000/svg">-->
      <!--            <path-->
      <!--                d="M4.293 7.707a1 1 0 0 1 1.414-1.414L4.293 7.707ZM8 10l.707.707a1 1 0 0 1-1.414 0L8 10Zm2.293-3.707a1 1 0 1 1 1.414 1.414l-1.414-1.414Zm-4.586 0 3 3-1.414 1.414-3-3 1.414-1.414Zm1.586 3 3-3 1.414 1.414-3 3-1.414-1.414Z"></path>-->
      <!--          </svg>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="px-4 mb-2" v-if="conversations.showTags">-->
      <!--        <span class="bg-blue-300 text-blue-900 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl">Customers</span>-->
      <!--        <span class="bg-purple-300 text-purple-900 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl">Pro Plan</span>-->
      <!--      </div>-->

      <!--      <div class="px-4 py-1 font-semibold text-md border-t pt-4 flex justify-between mr-1 mt-2">-->
      <!--        <span class="p-1">History</span>-->
      <!--        <div class="p-1.5 hover:bg-gray-200 rounded-lg cursor-pointer transition"-->
      <!--             @click="conversations.toggleShowHistory()"-->
      <!--        >-->
      <!--          <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"-->
      <!--               v-if="conversations.showHistory"-->
      <!--          >-->
      <!--            <path-->
      <!--                d="M4.293 9.293a1 1 0 0 0 1.414 1.414L4.293 9.293ZM8 7l.707-.707a1 1 0 0 0-1.414 0L8 7Zm2.293 3.707a1 1 0 0 0 1.414-1.414l-1.414 1.414Zm-4.586 0 3-3-1.414-1.414-3 3 1.414 1.414Zm1.586-3 3 3 1.414-1.414-3-3-1.414 1.414Z"></path>-->
      <!--          </svg>-->
      <!--          <svg width="16" height="16"-->
      <!--               v-if="!conversations.showHistory"-->
      <!--               xmlns="http://www.w3.org/2000/svg">-->
      <!--            <path-->
      <!--                d="M4.293 7.707a1 1 0 0 1 1.414-1.414L4.293 7.707ZM8 10l.707.707a1 1 0 0 1-1.414 0L8 10Zm2.293-3.707a1 1 0 1 1 1.414 1.414l-1.414-1.414Zm-4.586 0 3 3-1.414 1.414-3-3 1.414-1.414Zm1.586 3 3-3 1.414 1.414-3 3-1.414-1.414Z"></path>-->
      <!--          </svg>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="px-4" v-if="conversations.showHistory">-->
      <!--        <div class="flex gap-2">-->
      <!--          <span class="text-gray-500">23m</span>-->
      <!--          <span class="hover:underline cursor-pointer">/products/t-shirt</span>-->
      <!--        </div>-->
      <!--        <div class="flex gap-2">-->
      <!--          <span class="text-gray-500">44m</span>-->
      <!--          <span class="hover:underline cursor-pointer">/about-us</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="px-4 py-1 font-semibold text-md border-t pt-4 flex justify-between mr-1 mt-2">
        <span class="p-1">Actions</span>
        <div class="p-1.5 hover:bg-gray-200 rounded-lg cursor-pointer transition"
             @click="conversations.toggleShowActions()"
        >
          <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
               v-if="conversations.showActions"
          >
            <path
                d="M4.293 9.293a1 1 0 0 0 1.414 1.414L4.293 9.293ZM8 7l.707-.707a1 1 0 0 0-1.414 0L8 7Zm2.293 3.707a1 1 0 0 0 1.414-1.414l-1.414 1.414Zm-4.586 0 3-3-1.414-1.414-3 3 1.414 1.414Zm1.586-3 3 3 1.414-1.414-3-3-1.414 1.414Z"></path>
          </svg>
          <svg width="16" height="16"
               v-if="!conversations.showActions"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.293 7.707a1 1 0 0 1 1.414-1.414L4.293 7.707ZM8 10l.707.707a1 1 0 0 1-1.414 0L8 10Zm2.293-3.707a1 1 0 1 1 1.414 1.414l-1.414-1.414Zm-4.586 0 3 3-1.414 1.414-3-3 1.414-1.414Zm1.586 3 3-3 1.414 1.414-3 3-1.414-1.414Z"></path>
          </svg>
        </div>
      </div>

      <transition name="widget-fade" mode="out-in">
        <div class="px-4" v-if="conversations.showActions">
          <div class="mt-2">
            <button
                class="hover:text-red-500  w-full transition-opacity duration-100 cursor-pointer font-semibold flex flex-row items-center gap-1 rounded-lg focus:outline-none whitespace-nowrap py-2 px-3 bg-gray-200  justify-center">

              <div class="flex shrink min-w-0">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                  <path fill-rule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                        clip-rule="evenodd"/>
                </svg>

                <span class="ml-1 truncate shrink min-w-0">
                  Delete
                </span>
              </div>

            </button>
          </div>
        </div>
      </transition>

    </div>
    <div class="font-medium flex flex-col gap-3 pt-4" v-else>

    </div>
  </div>
</template>

<style scoped>
.contenteditable br {
  display: none
}
</style>