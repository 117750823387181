<script setup>
import {useWidgetStore} from "@/stores/widget.store";
import {onMounted, ref} from "vue";
import {getHumanDate, minutesToEstimatedText, truncate, unread} from "@/utils";

const widget = useWidgetStore();

onMounted(() => {
  widget.fetch();
})

function startNewConversation() {
  widget.viewConversation('new');
  widget.navigate('conversation');
  setTimeout(() => {
    document.getElementById('body-input').focus();
  }, 500);
}

const body = ref('');

async function sendMessage() {
  if (body.value === '') return;
  if (widget.selected === 'new') {
    await widget.createConversation(body.value);
  } else {
    await widget.postMessage(body.value);
  }
  body.value = '';
  // await new Audio('/drop_004.ogg').play();
  window.parent.postMessage('sobel_new_message', '*');
}

const email = ref('');

function fillEmail() {
  widget.fillEmail(email.value);
}

function viewConversation(secret) {
  widget.viewConversation(secret);
  widget.navigate('conversation');
  setTimeout(() => {
    document.getElementById('body-input').focus();
    widget.scrollToBottom();
  }, 500);
  window.parent.postMessage('sobel_read', '*');
}

function close() {
  window.parent.postMessage('sobel_close', '*');
}

</script>

<template>
  <div class="h-screen w-screen bg-black" v-if="widget.failed">
    <div style="height: 100%; " class="flex">
      <div class="m-auto text-white">An error occurred</div>
    </div>
  </div>
  <div class="h-screen w-screen bg-black" v-if="widget.loaded">
    <div style="height: 100%; " class="bg-white relative ">

      <div class="absolute bg-gradient home" style="height: 100%"></div>

      <div class="absolute home bg-transparent"
           :class="{ 'gradient': widget.tab === 'home', 'mini': widget.tab !== 'home' }">
      </div>

      <div class="relative flex flex-col h-full" style="width: 100%; min-width: 300px;">


        <Transition name="widget-fade" mode="out-in">

          <div v-if="widget.tab === 'conversation'">
            <div class="grow select-none">
              <div v-if="widget.selected !== 'new'"
                   class="h-16 rounded-t-lg text-white text-center flex bg-prominent gap-4">
                <div @click="widget.navigate('messages')"
                     class="cursor-pointer transition hover:bg-black hover:bg-opacity-20 bg-transparent flex flex-col justify-center p-4 rounded-lg m-2">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"
                       class="mx-auto w-4 h-4">
                    <path opacity="1" fill="white"
                          d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
                  </svg>
                </div>
                <img v-if="widget.conversation.last_user_id>0"
                     :src="widget.getTeamMember(widget.conversation.last_user_id).avatar_url"
                     class="w-8 h-8 rounded-full mt-4 "
                     :alt="widget.getTeamMember(widget.conversation.last_user_id).name">
                <div class="flex flex-col text-left mt-2.5 " v-if="widget.conversation.last_user_id">
                  <div class="font-medium text">{{ widget.getTeamMember(widget.conversation.last_user_id).name }}</div>
                  <div class="text-sm text-gray-100">Active
                    {{ getHumanDate(widget.getTeamMember(widget.conversation.last_user_id).last_active_at) }}
                  </div>
                </div>
                <div v-else
                     class="h-16 rounded-t-lg text-white text-center flex flex-col justify-center font-medium text-lg bg-prominent">
                  Conversation
                </div>
                <div class="grow"></div>
                <div @click="close"
                     class="transition hover:bg-black hover:bg-opacity-20 p-3 rounded-lg h-min m-2 cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"
                       class="lucide lucide-x">
                    <path d="M18 6 6 18"/>
                    <path d="m6 6 12 12"/>
                  </svg>
                </div>
              </div>

              <div v-if="widget.selected === 'new'" class="bg-prominent h-48 rounded-t-lg">
                <div class=" text-white text-center flex gap-4 justify-between header">
                  <div @click="widget.navigate('messages')"
                       class="cursor-pointer ml-4 mt-4 transition hover:bg-black hover:bg-opacity-20 mb-4 p-1 bg-transparent flex flex-col justify-center w-8 h-8 rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"
                         class="mx-auto">
                      <path opacity="1" fill="white"
                            d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
                    </svg>
                  </div>
                  <div
                      class="h-16 rounded-t-lg text-white text-center flex flex-col justify-center font-medium text-lg bg-prominent">
                    {{ widget.website.name }}
                  </div>
                  <div class="w-8 mr-4">

                  </div>
                </div>
                <div class="flex justify-center">
                  <div class="flex ">
                    <img :src="widget.website.third_most_active_user.avatar_url"
                         :alt="widget.website.third_most_active_user.name" v-if="widget.website.third_most_active_user"
                         class="w-12 h-12 rounded-full -mr-3">
                    <img :src="widget.website.second_most_active_user.avatar_url"
                         :alt="widget.website.second_most_active_user.name"
                         v-if="widget.website.second_most_active_user"
                         class="w-12 h-12 rounded-full -mr-3">
                    <img :src="widget.website.most_active_user.avatar_url" :alt="widget.website.most_active_user.name"
                         v-if="widget.website.most_active_user" class="w-12 h-12 rounded-full ">
                  </div>
                </div>
                <div class="mt-4 text-center text-white font-medium">
                  {{ minutesToEstimatedText(widget.website.estimated_response_minutes) }}
                </div>
                <div class="text-center text-white font-light">
                  Ask us anything or share your feedback
                </div>
              </div>

              <div class="overflow-y-scroll" id="conversation"
                   :style="{ 'height': widget.selected === 'new' ? 'calc(100vh - 16rem + 7px)' : 'calc(100vh - 8rem + 7px)' }">

                <TransitionGroup name="list" tag="div"
                                 class="list-group flex flex-col-reverse gap-4 overflow-y-scroll py-4"
                                 style="max-height: 100%"
                                 v-if="widget.conversation">
                  <div v-for="message in widget.conversation.messages" :key="message.id" class="flex-none">
                    <div class="flex gap-2 text-select list-group-item mx-4"
                         v-if="message.user_id>0"
                         :class="{ 'justify-end': message.is_from_visitor }">
                      <img :src="widget.getTeamMember(message.user_id).avatar_url" class="w-8 h-8 rounded-full mt-1"
                           alt="" v-if="!message.is_from_visitor">
                      <div class="p-3 rounded-lg text-sm" style="width: 80%" v-if="message.type === 'text'"
                           :class="{ 'bg-prominent text-white': message.is_from_visitor, 'bg-gray-light': !message.is_from_visitor }">
                        {{ message.body }}
                      </div>
                      <div v-if="message.type === 'email_prompt'" class="flex justify-start gap-3">
                        <img src="./../../assets/logo.png" class="w-8 h-8 rounded-lg" alt="">
                        <div class="bg-gray-light p-3 rounded-lg text-sm" style="width: 80%"
                             v-if="widget.conversation.email === ''">
                          Hey! If you want to be notified about this conversation, enter your email.
                          <div class="flex gap-2">
                            <input placeholder="Email" class="grow mt-2 p-2 rounded focus:outline-none" v-model="email"
                                   @keyup.enter="fillEmail">
                            <button class="flex-none bg-black mt-2 rounded w-9 h-9 p-1.5 " @click="fillEmail">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                   stroke="white" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div class="bg-gray-light p-3 rounded-lg text-sm" style="width: 80%"
                             v-if="widget.conversation.email !== ''">
                          You will be notified about this conversation to <span class="font-bold">{{
                            widget.conversation.email
                          }}</span>.
                        </div>
                      </div>
                    </div>

                    <div class="flex justify-end">
                      <span class="text-gray-500 text-sm text-right mt-1 mr-4"
                            v-if="widget.getLastSeenMessage(widget.conversation).id === message.id">Seen</span>
                      <span class="text-gray-500 text-sm text-right mt-1 mr-4 opacity-0"
                            v-if="widget.getLastSeenMessage(widget.conversation).id !== message.id && message.id === widget.conversation.messages[0].id">Not
                        seen yet</span>
                    </div>
                  </div>
                </TransitionGroup>


              </div>

            </div>
            <div v-if="widget.tab === 'conversation' && !widget.loading"
                 class="flex-none h-14 text-sm flex justify-between"
                 style="box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.05);">
              <input placeholder="Type a reply..." v-model="body" autocomplete="off" id="body-input"
                     @keyup.enter="sendMessage" class="ml-6 focus:outline-none w-full">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   class="w-5 h-5 stroke-gray-400 transition hover:stroke-black cursor-pointer m-4">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"/>
              </svg>
            </div>


          </div>

          <div class="rounded-lg grow select-none bg-transparent"
               v-else-if="widget.tab === 'messages' && !widget.loading">
            <div
                class="h-16 rounded-t-lg text-white text-center flex flex-col justify-center font-medium text-lg bg-prominent header">
              <div class="flex justify-between">
                <div class="opacity-0 p-3 m-1">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"
                     class="lucide lucide-x">
                  <path d="M18 6 6 18"/>
                  <path d="m6 6 12 12"/>
                </svg> -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="lucide lucide-message-square-text">
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/>
                    <path d="M13 8H7"/>
                    <path d="M17 12H7"/>
                  </svg>
                </div>
                <div class="flex flex-col justify-center">
                  Messages
                </div>
                <div @click="close"
                     class="transition hover:bg-black hover:bg-opacity-20 p-3 rounded-lg h-min m-2 cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"
                       class="lucide lucide-x">
                    <path d="M18 6 6 18"/>
                    <path d="m6 6 12 12"/>
                  </svg>
                </div>
              </div>
            </div>

            <div class="overflow-y-scroll" style="height: calc(100vh - 10rem + 16px);">
              <div class="h-20 border-b flex cursor-pointer transition hover:bg-gray-100 text-sm"
                   :class="{'font-semibold':unread(conversation, false)}"
                   v-for="conversation in widget.conversations" @click="viewConversation(conversation.secret)">
                <img :src="widget.getTeamMember(conversation.last_user_id).avatar_url"
                     class="w-10 h-10 rounded-full mt-5 ml-5 mr-4"
                     :alt="widget.getTeamMember(conversation.last_user_id).name" v-if="conversation.last_user_id">
                <div class="ml-4" v-else></div>
                <div>
                  <div class="mt-5">{{ truncate(conversation.last_message, 36) }}</div>
                  <div class="text-sm text-gray-500">
                    <span v-if="conversation.profile">{{ truncate(conversation.profile.name) }}, </span>
                    <span>{{ getHumanDate(conversation.last_activity_at) }}</span>
                  </div>
                </div>
                <!--                <span class="relative flex h-3 w-3 mt-1 ml-auto mt-8 mr-6" v-if="unread(conversation, false)">-->
                <!--                  <span-->
                <!--                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>-->
                <!--                  <span class="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span></span>-->
              </div>
            </div>

            <div class="absolute bottom-28 w-full flex justify-center">
              <button
                  class="bg-prominent text-white rounded-xl px-4 py-2 shadow-xl cursor-pointer border hover:brightness-90 transition"
                  @click="startNewConversation">
                Send us a message
              </button>
            </div>
          </div>
          <div class="grow " v-else-if="widget.loading">
            <div
                class="h-16 rounded-t-lg text-white text-center flex flex-col justify-center font-medium text-lg bg-prominent header">
            </div>
          </div>

          <!--        Home-->
          <div class="select-none grow rounded-lg overflow-y-scroll" v-else-if="widget.tab === 'home'">
            <div class="flex justify-between p-4">
              <span class="text-white font-medium text-3xl mt-4 ml-5">{{ widget.website.name }}</span>
              <div>
                <div @click="close"
                     class="mt-3 transition hover:bg-black hover:bg-opacity-20 p-3 rounded-lg h-min m-2 cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"
                       class="lucide lucide-x text-white">
                    <path d="M18 6 6 18"/>
                    <path d="m6 6 12 12"/>
                  </svg>
                </div>
              </div>
            </div>

            <div class="flex  mt-4 ml-4 mr-4 ml-8">
              <img :src="widget.website.third_most_active_user.avatar_url"
                   :alt="widget.website.third_most_active_user.name" v-if="widget.website.third_most_active_user"
                   class="w-12 h-12 rounded-full -mr-3">
              <img :src="widget.website.second_most_active_user.avatar_url"
                   :alt="widget.website.second_most_active_user.name" v-if="widget.website.second_most_active_user"
                   class="w-12 h-12 rounded-full -mr-3">
              <img :src="widget.website.most_active_user.avatar_url" :alt="widget.website.most_active_user.name"
                   v-if="widget.website.most_active_user" class="w-12 h-12 rounded-full ">
            </div>

            <div class="p-4 mt-8 flex flex-col">
              <span class="text-white font-medium text-3xl ml-5">Hey 👋</span>
              <span class="text-white font-medium text-3xl ml-5">How can we help you?</span>
            </div>

            <div class="bg-white p-4 m-4 rounded-lg shadow-xl cursor-pointer text-sm hover-text-prominent border"
                 @click="startNewConversation">
              <div class="font-medium">Send us a message</div>
              <div class="text-gray-500">{{ minutesToEstimatedText(widget.website.estimated_response_minutes) }}</div>
            </div>

            <div class="bg-white p-2 m-4 rounded-lg shadow-xl cursor-pointer text-sm border">
              <div class="hover:bg-gray-100 p-2 rounded-lg">How to get started with our product?</div>
              <div class="hover:bg-gray-100 p-2 rounded-lg">Integration with Amazon</div>
              <div class="hover:bg-gray-100 p-2 rounded-lg">Adding new member to dashboard</div>
              <div class="hover:bg-gray-100 p-2 rounded-lg">How to export data to file</div>
              <div class="hover:bg-gray-100 p-2 rounded-lg">GDPR compliance</div>
            </div>
          </div>

        </Transition>
        <!--        Menu-->
        <div v-if="widget.tab !== 'conversation'" class="flex-none h-20 grid grid-cols-2 text-sm  "
             style="box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.05);">
          <div @click="widget.navigate('home')" class="text-center cursor-pointer stroke-black font-medium button"
               :class="{ 'selected': widget.tab === 'home' }">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              class="w-7 h-7 m-auto mt-4">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="w-6 h-6 m-auto mt-5">
              <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/>
              <polyline points="9 22 9 12 15 12 15 22"/>
            </svg>
            <div class="mt-0.5">Home</div>
          </div>
          <div @click="widget.navigate('messages')" class="text-center cursor-pointer stroke-black font-medium button "
               :class="{ 'selected': widget.tab === 'messages' }">

            <div class="relative w-7 m-auto">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                class="w-7 h-7 m-auto mt-4">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
              </svg> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="w-6 h-6 m-auto mt-5">
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/>
                <path d="M13 8H7"/>
                <path d="M17 12H7"/>
              </svg>
              <div style="color:white;" v-if="widget.unreadCount > 0"
                   class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-3 ">
                {{ widget.unreadCount }}
              </div>

            </div>
            <div class="mt-0.5">Messages</div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.bg-gradient {
  background: linear-gradient(180deg, v-bind('widget.website.color') 20%, rgba(255, 255, 255, 1) 70%);
}

.gradient {
  margin-top: 80vh;
  height: 100vh;
  background: white;
}

.mini {
  margin-top: 4rem;
  height: 100vh;
  background: white;
}

.home {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.button:hover,
.selected div {
  color: v-bind('widget.website.color')
}

.button:hover,
.selected svg {
  stroke: v-bind('widget.website.color')
}

.bg-prominent {
  background-color: v-bind('widget.website.color');
}

.hover-bg-prominent {
  background-color: v-bind('widget.website.color');
}

.hover-text-prominent:hover {
  color: v-bind('widget.website.color');
}

.list-enter-active,
.list-leave-active {
  transition: all 0.2s ease;
}

.list-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.list-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.list-leave-from {
  opacity: 1;
  display: none;
}

.list-leave-to {
  opacity: 0;
  display: none;

}

.bg-gray-light {
  background-color: #F2F2F2;
}

.widget-fade-enter-active,
.widget-fade-leave-active {
  transition: opacity 0.2s ease;
}

.widget-fade-enter-from,
.widget-fade-leave-to {
  opacity: 0;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  select,
  textarea,
  input {
    font-size: 16px;
  }
}
</style>