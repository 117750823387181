import {createRouter, createWebHistory} from 'vue-router'
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import Inbox from "@/views/Dashboard/Inbox/Inbox.vue";
import Widget from "@/views/Widget/Widget.vue";
import Profile from "@/views/Profile/Profile.vue";
import Password from "@/views/Profile/Password.vue";
import Customization from "@/views/Dashboard/Widget/Customization.vue";

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        children: [
            {
                path: 'websites/:id/inbox/:status',
                name: 'website',
                component: Inbox
            },
            {
                path: 'websites/:id/inbox/:status/:secret',
                name: 'conversation',
                component: Inbox
            },
            {
                path: 'profile/personal',
                name: 'personal',
                component: Profile
            },
            {
                path: 'profile/password',
                name: 'password',
                component: Password
            },
            {
                path: 'websites/:id/widget/customization',
                name: 'customization',
                component: Customization
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/widget/:key',
        name: 'widget',
        component: Widget
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
