<script setup>

import TopBar from "@/shared/TopBar.vue";
import {useAuthStore} from "@/stores/auth.store";
import {useToast} from "vue-toastification";
import {toastConfig} from "@/constants";

const auth = useAuthStore();
const toast = useToast();

async function save() {
  try {
    await auth.changePassword();
    toast.success('Password changed!', toastConfig)
  } catch (e) {
    toast.error(e.message.capitalize(), toastConfig);
  }
}
</script>

<template>
  <div class="w-full">
    <TopBar>
      Password
    </TopBar>


    <div class="m-12 flex flex-col gap-5">

      <form class="flex flex-col gap-5" @submit.prevent="save">
        <div class="text-md w-1/3">
          <label class="font-semibold">Old password</label>
          <div class="mt-2">
            <input type="password" placeholder="Old password" v-model="auth.passwordForm.old_password"
                   class="form-control">
          </div>
        </div>
        <div class="text-md w-1/3">
          <label class="font-semibold">New password</label>
          <div class="mt-2">
            <input type="password" placeholder="New password" v-model="auth.passwordForm.new_password"
                   class="form-control">
          </div>
        </div>
        <div class="text-md w-1/3">
          <label class="font-semibold">Repeat password</label>
          <div class="mt-2">
            <input type="password" placeholder="Repeat password" v-model="auth.passwordForm.new_password_confirmation"
                   class="form-control">
          </div>
        </div>
        <div class="text-md w-1/3">
          <button type="submit" class="btn">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>

</style>