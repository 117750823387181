<template>
    <!--  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"-->
    <!--       stroke="currentColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"-->
    <!--       class="lucide lucide-circle-user-round w-4 h-4 mt-0.5">-->
    <!--    <path d="M18 20a6 6 0 0 0-12 0"/>-->
    <!--    <circle cx="12" cy="10" r="4"/>-->
    <!--    <circle cx="12" cy="12" r="10"/>-->
    <!--  </svg>-->
    <!--  <svg class="interface-icon o__inbox2 o__inbox2__multiple-people" width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M10.5 5.97a2.5 2.5 0 1 0-2.31-3.46 3.99 3.99 0 0 1 1.3 3.247c.309.137.65.213 1.01.213Zm4.5 6h-2.837a6.384 6.384 0 0 0-2.132-3.064c-.327-.268-.84-.578-1.487-.84.244-.287.448-.607.605-.954A6.512 6.512 0 0 1 10.5 6.97c1.742 0 3.12.713 3.592 1.106 1.139.888 1.607 2.044 1.793 2.903.117.54-.333.99-.885.99Zm-7-6.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-7 8.5c-.552 0-1.002-.452-.885-.991.186-.859.654-2.015 1.793-2.903C2.38 9.683 3.758 8.97 5.5 8.97s3.12.713 3.592 1.106c1.139.888 1.607 2.044 1.793 2.903.117.54-.333.99-.885.99H1Z"></path></svg>-->

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width: 20px; height: 20px;">
        <path
            d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
    </svg>
</template>
<script>
export default {
    name: 'AllIcon'
}
</script>
