<script setup>

import {useRoute} from "vue-router";

const route = useRoute();
const websites = useWebsiteStore();

import PaletteIcon from "@/icons/PaletteIcon.vue";
import {useWebsiteStore} from "@/stores/website.store";
</script>

<template>
  <div class="w-52 pb-2 border-r flex flex-col " style="background-color: #F1F1F1">

    <div class="flex justify-between m-4 ml-6">
      <div class="font-semibold text-lg">Widget</div>
    </div>

    <router-link :to="'/websites/' + websites.website.id + '/widget/customization'"
                 class="flex text-sm gap-2 mx-4 px-3.5 py-2 hover:bg-gray-200 rounded-lg cursor-pointer"
                 :class="{'bg-white':route.fullPath.includes('customization')}">
      <PaletteIcon class="mt-0.5"/>
      <div class="flex justify-between link w-full">
        <div>Options</div>
      </div>
    </router-link>

  </div>
</template>

<style scoped>

</style>