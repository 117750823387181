import {defineStore} from "pinia";
import {ref} from "vue";
import {api, socket} from "@/api";
import {useRoute, useRouter} from "vue-router";

export const useWebsiteStore = defineStore('website', () => {

    const websites = ref([]);
    const website = ref({
        name: 'Loading',
        color: '#ffffff',
        bubble_color: '#ffffff',
    });
    const route = useRoute();
    const router = useRouter();
    const loaded = ref(false);
    const team = ref([]);

    function getTeamMember(id) {
        return team.value.find(member => member.id === id);
    }

    async function fetch() {
        try {
            const response = await api.get('websites');
            websites.value = response.data;

            const websiteID = route.params.id;
            let w = null;
            if (websiteID) {
                w = websites.value.find(website => website.id === parseInt(websiteID));
                team.value = (await api.get('websites/' + websiteID + '/team')).data;
            }
            if (w === undefined || w === null) {
                await router.push('/websites/' + websites.value[0].id + '/inbox/awaiting');
                await fetch();
                return;
            }
            website.value = w;
            widgetColor.value.hex = website.value.color;
            bubbleColor.value.hex = website.value.bubble_color;
            loaded.value = true;

            socket.emit('subscribe_website', localStorage.getItem('token'), website.value.id);

            return response.data;
        } catch (err) {
            throw new Error(err.response.data.message);
        }
    }

    async function save(patch) {
        try {
            const response = await api.put('websites/' + website.value.id, patch);
            return response.data;
        } catch (err) {
            throw new Error(err.response.data.message);
        }
    }

    const widgetColor = ref({
        hex: '#194d33e6',
        hsl: {
            h: 0,
            s: 0.5,
            l: 0.2,
            a: 0.9
        },
        hsv: {
            h: 150,
            s: 0.66,
            v: 0.30,
            a: 0.9
        },
        rgba: {
            r: 159,
            g: 96,
            b: 43,
            a: 0.9
        },
        a: 1,
    });

    const bubbleColor = ref({
        hex: '#194d33e6',
        hsl: {
            h: 0,
            s: 0.5,
            l: 0.2,
            a: 0.9
        },
        hsv: {
            h: 150,
            s: 0.66,
            v: 0.30,
            a: 0.9
        },
        rgba: {
            r: 159,
            g: 96,
            b: 43,
            a: 0.9
        },
        a: 1,
    });

    return {fetch, websites, website, loaded, team, getTeamMember, save, widgetColor, bubbleColor};

});